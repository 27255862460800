/* @flow */
import React from 'react';
import classNames from 'classnames';

import Button3D from '../Button3D/Button3D';
import styles from './SubmitButton.module.scss';

const SubmitButton = ({
  children = 'Continue',
  loading,
  className,
  ...rest
}: {
  children: string,
  className: string,
  loading: boolean,
}) => (
  <Button3D
    type="submit"
    className={classNames([styles['btn-submit'], className])}
    loading={loading}
    disabled={loading}
    {...rest}
  >
    {children}
  </Button3D>
);

export default SubmitButton;
