/* @flow */
import { useEffect } from 'react';

const useScrollToTop = ({ location, smooth }) => {
  useEffect(() => {
    window.scrollTo({
      behavior: smooth ? 'smooth' : 'auto',
      left: 0,
      top: 0,
    });
  }, [location.pathname, smooth]);
  return {};
};

export default useScrollToTop;
