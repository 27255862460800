/* @flow */
import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { pluralUrls } from '@pluralcom/plural-js-utils';

import styles from './Navbar.module.scss';

type Props = {
  children: React$Node,
  viewer?: ?{
    profile: {
      id: string,
      username: string,
    },
  },
};

const Navbar = ({ viewer, children }: Props) => {
  const { navbarProfile: profile } = viewer || {};
  return (
    <nav className={styles['pl-navbar']}>
      <div className={styles.children}>{children}</div>
      <div>
        {profile && (
          <a
            className={styles['pl-navbar__right__name']}
            href={`${pluralUrls.getWebUrl()}/${profile.username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {profile.username}
          </a>
        )}
      </div>
    </nav>
  );
};

export { Navbar as PureNavbar };

export default createRefetchContainer(
  Navbar,
  {
    viewer: graphql`
      fragment Navbar_viewer on ViewerType {
        navbarProfile: profile {
          id
          username
        }
      }
    `,
  },
  graphql`
    query NavbarRefetchQuery {
      viewer {
        ...Navbar_viewer
      }
    }
  `,
);
