/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation CreateCategoryTagMutation($input: CreateCategoryTagInput!) {
    createCategoryTag(input: $input) {
      categoryTag {
        id
        title
        tags_with_this_category {
          id
          title
        }
      }
      tagsEdges {
        edges {
          node {
            id
            title
            slug
            remote
            do_not_suggest
            boost
            block
            used_at
            corrected_to_tag {
              id
              title
            }
            categories {
              id
              title
            }
            synonyms {
              id
              title
            }
            skillTags {
              id
              title
            }
            tags_with_this_category {
              id
              title
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;

const sharedUpdater = (store, newCategoryTag, primaryTagId, newEdges) => {
  const primaryTag = primaryTagId && store.get(primaryTagId);
  if (primaryTag) {
    let primaryTagCategories = primaryTag.getLinkedRecords('categories');
    if (primaryTagCategories) {
      primaryTagCategories = primaryTagCategories.concat(newCategoryTag);
      primaryTag.setLinkedRecords(primaryTagCategories, 'categories');
    }

    // handle tags_with_this_category
    const categoryTag = store.get(newCategoryTag.id);
    if (categoryTag) {
      let categoryTagTagsWithThisCategory = categoryTag.getLinkedRecords(
        'tags_with_this_category',
      );
      if (categoryTagTagsWithThisCategory) {
        categoryTagTagsWithThisCategory = [primaryTag].concat(
          categoryTagTagsWithThisCategory,
        );
        categoryTag.setLinkedRecords(
          categoryTagTagsWithThisCategory,
          'tags_with_this_category',
        );
      }
    }
  }

  if (!newEdges?.length) {
    return;
  }

  const rootField = store.getRoot();
  const viewerField = rootField.getLinkedRecord('viewer');

  const _addToConnection = ([connectionName, filters]) => {
    const connection = ConnectionHandler.getConnection(
      viewerField,
      connectionName,
      filters,
    );
    if (connection) {
      newEdges.forEach((newEdge) => {
        ConnectionHandler.insertEdgeBefore(connection, newEdge);
      });
      connection.setValue(
        connection.getValue('totalCount') + newEdges.length,
        'totalCount',
      );
    }
  };

  [
    ['TagsViewAll_tags'],
    ['TagsViewCategories_tags'],
    ['CategoryTagAutoComplete_suggestionTagsCategories', { term: '' }],
  ].forEach((connectionName) => _addToConnection(connectionName));
};

export default (
  {
    title,
    primaryTagId,
  }: {
    title: string,
    primaryTagId: string,
  },
  {
    // used for opup
    categoryTagId,
  }: {
    categoryTagId?: string,
  } = {},
) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        title,
        primaryTagId,
      },
    };

    commitMutation({
      mutation,
      variables,

      optimisticUpdater: (store) => {
        const id =
          categoryTagId || `OPUP:Tag:${`${Math.random()}`.substring(2, 10)}`;

        const categoryTag = categoryTagId
          ? store.get(categoryTagId)
          : store.create(id, 'Tag');
        if (!categoryTagId) {
          categoryTag.setValue(id, 'id');
          categoryTag.setValue(title, 'title');
          categoryTag.setValue(new Date().toISOString(), 'used_at');
          categoryTag.setValue(new Date().toISOString(), 'created_at');
        }

        sharedUpdater(store, categoryTag, primaryTagId);
      },
      updater: (store) => {
        const payload = store.getRootField('createCategoryTag');
        const newCategoryTag = payload.getLinkedRecord('categoryTag');
        const error = payload.getLinkedRecord('error');

        const tagsEdges = payload
          .getLinkedRecord('tagsEdges')
          ?.getLinkedRecords('edges');

        if (!error) {
          sharedUpdater(store, newCategoryTag, primaryTagId, tagsEdges);
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
