/**
 * @generated SignedSource<<4814c9d0c85f9c5b26e9f65d4d9fae00>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type CategoryTagAutoComplete_viewer$fragmentType: FragmentType;
export type CategoryTagAutoComplete_viewer$data = {|
  +suggestionTagsCategories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
      |},
    |}>,
  |},
  +$fragmentType: CategoryTagAutoComplete_viewer$fragmentType,
|};
export type CategoryTagAutoComplete_viewer$key = {
  +$data?: CategoryTagAutoComplete_viewer$data,
  +$fragmentSpreads: CategoryTagAutoComplete_viewer$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "term"
    },
    {
      "defaultValue": "CATEGORY",
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "suggestionTagsCategories"
        ]
      }
    ]
  },
  "name": "CategoryTagAutoComplete_viewer",
  "selections": [
    {
      "alias": "suggestionTagsCategories",
      "args": [
        {
          "kind": "Variable",
          "name": "term",
          "variableName": "term"
        }
      ],
      "concreteType": "TagConnection",
      "kind": "LinkedField",
      "name": "__CategoryTagAutoComplete_suggestionTagsCategories_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TagEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};

(node/*: any*/).hash = "6d2264c8997c0590ed4e73bd80b9da59";

module.exports = ((node/*: any*/)/*: Fragment<
  CategoryTagAutoComplete_viewer$fragmentType,
  CategoryTagAutoComplete_viewer$data,
>*/);
