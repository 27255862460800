/**
 * @generated SignedSource<<51f1c1c94c84ef7af771180d42c4d260>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddTagToFAQuestionInput = {|
  question_id?: ?string,
  tag_id?: ?string,
  question?: ?string,
  tag_title?: ?string,
  clientMutationId?: ?string,
|};
export type AddTagToFaqQuestionMutation$variables = {|
  input: AddTagToFAQuestionInput,
|};
export type AddTagToFaqQuestionMutation$data = {|
  +addTagToFAQuestion: ?{|
    +error: ?{|
      +title: ?string,
      +message: ?string,
      +technical_message: ?string,
    |},
    +newQuestionTagEdge: ?{|
      +node: ?{|
        +id: string,
        +hashtag: {|
          +id: string,
          +title: ?string,
          +synonyms: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
        |},
      |},
    |},
  |},
|};
export type AddTagToFaqQuestionMutation = {|
  variables: AddTagToFaqQuestionMutation$variables,
  response: AddTagToFaqQuestionMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "technical_message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "FAQuestionTagEdge",
  "kind": "LinkedField",
  "name": "newQuestionTagEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FAQuestionTag",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "hashtag",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "synonyms",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddTagToFaqQuestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTagToFAQuestionPayload",
        "kind": "LinkedField",
        "name": "addTagToFAQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddTagToFaqQuestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTagToFAQuestionPayload",
        "kind": "LinkedField",
        "name": "addTagToFAQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04cb9c77264e826abb1e92f24951e931",
    "id": null,
    "metadata": {},
    "name": "AddTagToFaqQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation AddTagToFaqQuestionMutation(\n  $input: AddTagToFAQuestionInput!\n) {\n  addTagToFAQuestion(input: $input) {\n    error {\n      title\n      message\n      technical_message\n      id\n    }\n    newQuestionTagEdge {\n      node {\n        id\n        hashtag {\n          id\n          title\n          synonyms {\n            id\n            title\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a4b34f6e70e5c3f5ffdf6f1db1247e42";

module.exports = ((node/*: any*/)/*: Mutation<
  AddTagToFaqQuestionMutation$variables,
  AddTagToFaqQuestionMutation$data,
>*/);
