/**
 * @generated SignedSource<<075731a7f83f22274ee62a8b403baa1f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveSynonymCategoryFromPrimaryTagInput = {|
  primaryTagId: string,
  synonymTagIds?: ?$ReadOnlyArray<?string>,
  categoryTagIds?: ?$ReadOnlyArray<?string>,
  skillTagIds?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type RemoveSynonymCategoryFromPrimaryTagMutation$variables = {|
  input: RemoveSynonymCategoryFromPrimaryTagInput,
|};
export type RemoveSynonymCategoryFromPrimaryTagMutation$data = {|
  +removeSynonymCategoryFromPrimaryTag: ?{|
    +primaryTag: ?{|
      +id: string,
      +title: ?string,
      +slug: ?string,
      +synonyms: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
      +categories: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
      +skillTags: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
    |},
    +updatedCategories: ?$ReadOnlyArray<?{|
      +id: string,
      +title: ?string,
      +tags_with_this_category: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
    |}>,
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type RemoveSynonymCategoryFromPrimaryTagMutation = {|
  variables: RemoveSynonymCategoryFromPrimaryTagMutation$variables,
  response: RemoveSynonymCategoryFromPrimaryTagMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "primaryTag",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "synonyms",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "skillTags",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "updatedCategories",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tags_with_this_category",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveSynonymCategoryFromPrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveSynonymCategoryFromPrimaryTagPayload",
        "kind": "LinkedField",
        "name": "removeSynonymCategoryFromPrimaryTag",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveSynonymCategoryFromPrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveSynonymCategoryFromPrimaryTagPayload",
        "kind": "LinkedField",
        "name": "removeSynonymCategoryFromPrimaryTag",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1bfc1d5cadc93ceda16fcc123d22f0f3",
    "id": null,
    "metadata": {},
    "name": "RemoveSynonymCategoryFromPrimaryTagMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveSynonymCategoryFromPrimaryTagMutation(\n  $input: RemoveSynonymCategoryFromPrimaryTagInput!\n) {\n  removeSynonymCategoryFromPrimaryTag(input: $input) {\n    primaryTag {\n      id\n      title\n      slug\n      synonyms {\n        id\n        title\n      }\n      categories {\n        id\n        title\n      }\n      skillTags {\n        id\n        title\n      }\n    }\n    updatedCategories {\n      id\n      title\n      tags_with_this_category {\n        id\n        title\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7de34ac4fa755d1f809e5b78a26f09fa";

module.exports = ((node/*: any*/)/*: Mutation<
  RemoveSynonymCategoryFromPrimaryTagMutation$variables,
  RemoveSynonymCategoryFromPrimaryTagMutation$data,
>*/);
