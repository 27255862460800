/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation CorrectTagTitleMutation($input: CorrectTagTitleInput!) {
    correctTagTitle(input: $input) {
      oldTag {
        id
        corrected_to_tag {
          id
          title
        }
      }
      tagsEdges {
        edges {
          node {
            id
            title
            slug
            remote
            do_not_suggest
            boost
            block
            used_at
            corrected_to_tag {
              id
              title
            }
            categories {
              id
              title
            }
            synonyms {
              id
              title
            }
            skillTags {
              id
              title
            }
            tags_with_this_category {
              id
              title
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;

const sharedUpdater = (
  /** relay store */
  store: Object,
  /** tagId to be corrected */
  tagId: string,
  /** provided correctedToTag */
  correctedToTag: { id?: string, title: string },
  /** newEdges returned by the backend */
  newEdges: ?Array<Object>,
  /** Optimistically created Tag nod */
  optimisticCorrectedToTagNode?: Object,
) => {
  const primaryTag = store.get(tagId);
  if (optimisticCorrectedToTagNode) {
    primaryTag.setLinkedRecord(
      optimisticCorrectedToTagNode,
      'corrected_to_tag',
    );
  }

  const rootField = store.getRoot();
  const viewerField = rootField.getLinkedRecord('viewer');

  const _addToConnection = ([connectionName, filters]) => {
    const connection = ConnectionHandler.getConnection(
      viewerField,
      connectionName,
      filters,
    );
    if (connection) {
      // new edges from the backend - append to connection
      if (newEdges) {
        newEdges.forEach((newEdge) => {
          ConnectionHandler.insertEdgeBefore(connection, newEdge);
        });
        connection.setValue(
          connection.getValue('totalCount') + newEdges.length,
          'totalCount',
        );
      }
      // new optimistic edge - tag doesn't already exist in store - created edge then append to connection
      if (
        optimisticCorrectedToTagNode &&
        (!correctedToTag.id || !store.get(correctedToTag.id))
      ) {
        const newEdge = ConnectionHandler.createEdge(
          store,
          connection,
          optimisticCorrectedToTagNode,
          'TagEdge' /* GraphQl Type for edge */,
        );
        ConnectionHandler.insertEdgeBefore(connection, newEdge);
        connection.setValue(
          connection.getValue('totalCount') + 1,
          'totalCount',
        );
      }
    }
  };

  // boosted by default
  [
    ['TagsViewAll_tags'],
    ['TagsViewUsed_tags'],
    ['TagsViewCurate_tags'],
    ['PrimaryTagAutoComplete_suggestionTags', { term: '' }],
    [
      'PrimaryTagAutoComplete_suggestionTags',
      { term: '', filter: { boost: true } },
    ],
  ]
    .filter(Boolean)
    .forEach((connectionName) => _addToConnection(connectionName));
};

const correctTagTitleMutation = ({
  tagId,
  correctedToTag,
}: {
  tagId: string,
  correctedToTag: {
    id: string,
    title: string,
  },
}) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        old_tag_id: tagId,
        new_tag_id: correctedToTag.id,
        new_title: correctedToTag.id ? undefined : correctedToTag.title,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticUpdater: (store) => {
        const id =
          correctedToTag.id ||
          `OPUP:Tag:${`${Math.random()}`.substring(2, 10)}`;
        // get or create corrected to tag
        const optimisticCorrectedToTagNode =
          store.get(id) || store.create(id, 'Tag');
        if (!optimisticCorrectedToTagNode.getValue('title')) {
          optimisticCorrectedToTagNode.setValue(id, 'id');
          optimisticCorrectedToTagNode.setValue(correctedToTag.title, 'title');
          optimisticCorrectedToTagNode.setValue(true, 'boost');
          optimisticCorrectedToTagNode.setValue(
            new Date().toISOString(),
            'used_at',
          );
        }

        sharedUpdater(
          store,
          tagId,
          correctedToTag,
          undefined,
          optimisticCorrectedToTagNode,
        );
      },
      updater: (store) => {
        const payload = store.getRootField('correctTagTitle');
        const error = payload.getLinkedRecord('error');

        const tagsEdges = payload
          .getLinkedRecord('tagsEdges')
          ?.getLinkedRecords('edges');

        if (!error) {
          sharedUpdater(store, tagId, correctedToTag, tagsEdges);
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });

export default correctTagTitleMutation;
