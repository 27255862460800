/* @flow */
import React, { PureComponent } from 'react';

import ReactCodeInput from 'react-code-input';
import classNames from 'classnames';
import styles from './TileCodeInputItem.module.scss';

type Props = {
  className?: ?string,
  type: 'text' | 'number' | 'password' | 'tel',
  fields: number,
  value: string,
  onChange: Function,
  name?: string,
  options?: Object,
  codeRef?: Function,
  touch?: Function,
  untouch?: Function,
  disabled?: boolean,
  autoFocus?: boolean,
  error?: string,
};

class TileCodeInputItem extends PureComponent<*, Props, *> {
  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      setTimeout(() => {
        const selectedElement = window.document.querySelector(
          `.${styles.container} input`,
        );
        if (selectedElement && selectedElement.focus) {
          selectedElement.focus();
        }
      }, 0);
    }
  }

  render() {
    const { className, error, codeRef, ...rest } = this.props;
    return (
      <div>
        <ReactCodeInput
          className={classNames([styles.container, className])}
          ref={codeRef}
          fields={6}
          type="number"
          {...rest}
        />
      </div>
    );
  }
}

export default TileCodeInputItem;
