import { logger, sentryHelpers } from '../../utils';

const configMiddlwareError = (config) => {
  const { onError } = config;
  return {
    ...config,
    onError: (err) => {
      logger.debugError('Graphql Commit Mutation error', err, { config });
      sentryHelpers.addBreadcrumb({
        level: 'error',
        category: 'graphql_error_mutation',
        message: `Commit Mutation Error: ${err?.message || err}`,
        // data: config,
      });
      sentryHelpers.captureException(err);
      return onError(err);
    },
  };
};

const configMiddleware = (config) => {
  if (!config) {
    return config;
  }
  return configMiddlwareError(config);
};

export default configMiddleware;
