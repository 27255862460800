/* @flow */

import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation RequestLoginPinMutation($input: RequestLoginPinInput!) {
    requestLoginPin(input: $input) {
      auth_reqid
      profile {
        id
        username
        name
      }
      error {
        message
      }
    }
  }
`;

export default ({
  email,
  phone,
  auth_reqid,
}: {
  email?: ?string,
  phone?: ?string,
  auth_reqid: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        email,
        phone,
        auth_reqid,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });
