/* @flow */
import slug from 'slug';

/**
 * Slugifies a string
 */
const slugify = (txt: string): string =>
  slug(txt, { replacement: '_', lower: true });

export default slugify;
