/* @flow weak */

import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation editUserParametersMutation($input: EditUserParametersInput!) {
    editUserParameters(input: $input) {
      user {
        id
        boost 
        blocked
      }
      error {
        technical_message
        message
      }
    }
  }
`;

export default ({
  user_id,
  boost,
  blocked,
}) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        user_id,
        boost,
        blocked
      }
    };

    const optimisticResponse = {
      error: null, 
      user: {
        user_id,
        boost, 
        blocked,
      }
    }

    commitMutation({
      mutation,
      variables,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });
