/* @flow */
import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import { Input } from 'antd';
import useOutSideClick from '../../../../hooks/useOutsideClick/useOutsideClick';

import styles from './AutoCompleteField.module.scss';

import type { PrimaryTagAutoComplete_viewer } from '../PrimaryTagAutoComplete/__generated__/PrimaryTagAutoComplete_viewer.graphql';
import type { CategoryTagAutoComplete_viewer } from '../CategoryTagAutoComplete/__generated__/CategoryTagAutoComplete_viewer.graphql';

type Props = {
  viewer: PrimaryTagAutoComplete_viewer | CategoryTagAutoComplete_viewer,
  placeholder: string,
  size: 'small' | 'default' | 'middle' | 'large',
  hide: boolean,
  onSubmit: (string, string) => void,
  className: string,
  relay: {
    refetch: Function,
  },
  defaultValue?: string,
  disableValueSubmission?: boolean,
  defaultFocus?: boolean,
};

const AutoCompleteField = ({
  viewer,
  placeholder = 'Type here...',
  size = 'small',
  hide,
  onSubmit,
  className,
  defaultValue,
  disableValueSubmission,
  defaultFocus = false,
  ...props
}: Props) => {
  const suggestionTags =
    viewer?.suggestionTags?.edges ||
    viewer?.suggestionTagsCategories?.edges ||
    [];
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [focus, setFocus] = useState(defaultFocus);
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (defaultFocus) {
      inputRef.current.focus();
    }
  }, [defaultFocus]);

  const _refetch = (searchTerm) => {
    props.relay.refetch((oldVariables) => ({
      ...oldVariables,
      term: searchTerm,
    }));
  };

  const _updateSearchTerm = ({ target: { value: newValue } }) => {
    setInputValue(newValue);
    _refetch(newValue);
  };

  const _onBlur = () => {
    setFocus(false);
    inputRef.current.blur();
    return hide && hide();
  };

  const _submit = (e) => {
    if (e.key === 'Enter' && !disableValueSubmission) {
      onSubmit(inputValue);
      setInputValue('');
      setFocus(false);
    }
  };

  const _submitInput = (value, id) => {
    onSubmit(value, id);
    setInputValue('');
    _onBlur();
  };

  const _handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      _onBlur();
    }
  };
  useOutSideClick(_handleClickOutside, 'mousedown');

  return (
    <div
      ref={wrapperRef}
      className={classNames([styles.container, styles[`size--${size}`]])}
    >
      <Input
        size={size}
        className={classNames([styles.input, className])}
        placeholder={placeholder}
        onChange={(e) => _updateSearchTerm(e)}
        onKeyUp={(e) => _submit(e)}
        value={inputValue}
        onFocus={() => setFocus(true)}
        ref={inputRef}
      />
      {focus ? (
        <div className={styles.dropdown}>
          {disableValueSubmission ||
          !inputValue.length ||
          suggestionTags.some(
            ({ node }) => node.title === inputValue,
          ) ? null : (
            <div
              className={styles.suggestion}
              role="presentation"
              onClick={() => _submitInput(inputValue)}
              onKeyDown={() => _submitInput(inputValue)}
            >
              {inputValue}
            </div>
          )}
          {suggestionTags.map(({ node }) => (
            <div
              key={node.id}
              role="presentation"
              className={styles.suggestion}
              onClick={() => _submitInput(node.title, node.id)}
              onKeyDown={() => _submitInput(node.title, node.id)}
            >
              {node.title}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default AutoCompleteField;
