/* @flow */
import validator from 'validator';
import inputsMaxLengths from '../../assets/data/inputsMaxLengths';

const isValidAuthVerificationCode = (str: string) =>
  str.length === inputsMaxLengths.authVerificationCode &&
  validator.isNumeric(str);

export default {
  isValidAuthVerificationCode,
};
