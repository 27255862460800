/**
 * @todo remove when chrome fixes
 * Hotfix for the relay error "Uncaught DOMException: Failed to execute 'postMessage' on 'Window': Response object could not be cloned."
 * https://pluralcom.slack.com/archives/GJ04WMD54/p1592832831000100
 * https://sentry.io/share/issue/4bd8c86dd1be40a0b4d10c9f5967e18a/
 */
const windowPostMessageHotfix = () => {
  if (window.postMessageRaw) {
    return;
  }
  window.postMessageRaw = window.postMessage;
  window.postMessage = (...args) => {
    try {
      return window.postMessageRaw(...args);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    return null;
  };
};

export default windowPostMessageHotfix;
