/* @flow */

/**
 * Retry middleware for React's lazy loading
 *
 * - Fixes the can't load chunk errors by retrying
 */
const lazyLoadRetry = (
  fn: Function,
  interval?: number = 150,
  retriesLeft?: number = 5,
  calcNextInterval: (
    currInterval: number,
    nextRetriesLeft: number
  ) => number = currInterval => currInterval * 2
): Promise<*> =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          lazyLoadRetry(
            fn,
            calcNextInterval(interval, retriesLeft - 1),
            retriesLeft - 1
          ).then(resolve, reject);
        }, interval);
      });
  });

export default lazyLoadRetry;
