/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';

import { commitMutation } from '../..';
import { setNodeLinkedRecords } from '../../relayUtils';

const mutation = graphql`
  mutation RemoveSynonymCategoryFromPrimaryTagMutation(
    $input: RemoveSynonymCategoryFromPrimaryTagInput!
  ) {
    removeSynonymCategoryFromPrimaryTag(input: $input) {
      primaryTag {
        id
        title
        slug
        synonyms {
          id
          title
        }
        categories {
          id
          title
        }
        skillTags {
          id
          title
        }
      }
      # needed to refetch the tags_with_this_category of the removed categories -- since the removed categories are no longer returned with PrimaryTag.
      updatedCategories {
        id
        title
        tags_with_this_category {
          id
          title
        }
      }
      error {
        message
      }
    }
  }
`;

export default ({
  primaryTagId,
  categoryTagIds = [],
  synonymTagIds = [],
  skillTagIds = [],
}: {
  primaryTagId: string,
  categoryTagIds?: Array<string>,
  synonymTagIds?: Array<string>,
  skillTagIds?: Array<string>,
}) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        primaryTagId,
        ...(categoryTagIds.length ? { categoryTagIds } : {}),
        ...(synonymTagIds.length ? { synonymTagIds } : {}),
        ...(skillTagIds.length ? { skillTagIds } : {}),
      },
    };

    commitMutation({
      mutation,
      variables,

      optimisticUpdater: (store) => {
        const primaryTag = store.get(primaryTagId);

        // handle categories and tags_with_this_category
        if (categoryTagIds.length) {
          // handle categories
          const records = primaryTag.getLinkedRecords('categories');
          if (records) {
            setNodeLinkedRecords(primaryTag, {
              categories: records.filter(
                (item) => !categoryTagIds.includes(item._dataID),
              ),
            });
          }
          // handle tags_with_this_category
          const categoryPrimaryTags = categoryTagIds.map((categoryTagId) =>
            store.get(categoryTagId),
          );
          categoryPrimaryTags.forEach((categoryPrimaryTag) => {
            const records2 = categoryPrimaryTag.getLinkedRecords(
              'tags_with_this_category',
            );
            if (records2) {
              const newTagsWithThisCategory = records2.filter(
                (item) => primaryTagId !== item._dataID,
              );
              setNodeLinkedRecords(categoryPrimaryTag, {
                tags_with_this_category: newTagsWithThisCategory,
              });
              /** delete the category from edges if no more tags_with_this_category exist - since backend makes tag not category if this happens */
              if (!newTagsWithThisCategory.length) {
                const rootField = store.getRoot();
                const viewerField = rootField.getLinkedRecord('viewer');

                const _deleteFromConnection = ([connectionName, filters]) => {
                  const connection = ConnectionHandler.getConnection(
                    viewerField,
                    connectionName,
                    filters,
                  );
                  if (connection) {
                    ConnectionHandler.deleteNode(
                      connection,
                      categoryPrimaryTag._dataID,
                    );
                  }
                };
                [
                  ['TagsViewCategories_tags'],
                  ['CategoryTagAutoComplete_suggestionTagsCategories'],
                ].forEach((connectionName) =>
                  _deleteFromConnection(connectionName),
                );
              }
            }
          });
        }
        // handle synonyms
        if (synonymTagIds.length) {
          const records = primaryTag.getLinkedRecords('synonyms');
          if (records) {
            setNodeLinkedRecords(primaryTag, {
              synonyms: records.filter(
                (item) => !synonymTagIds.includes(item._dataID),
              ),
            });
          }
        }
        // handle skillTags
        if (skillTagIds.length) {
          const records = primaryTag.getLinkedRecords('skillTags');
          if (records) {
            setNodeLinkedRecords(primaryTag, {
              skillTags: records.filter(
                (item) => !skillTagIds.includes(item._dataID),
              ),
            });
          }
        }
      },

      onCompleted: resolve,
      onError: reject,
    });
  });
