/** @flow */
import Logger from './Logger/Logger';

export const LOGGER_IS_ENABLED = true;

const loggerLevel = process.env.REACT_APP_LOGGER_LEVEL || 'trace';

/**
 * Export a singelton instance of the Logger
 */
export default new Logger({
  isEnabled: LOGGER_IS_ENABLED,
  loggerLevel,
});
