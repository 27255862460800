/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DeletePrimaryTagMutation($input: DeletePrimaryTagInput!) {
    deletePrimaryTag(input: $input) {
      primaryTag {
        id
      }
      error {
        message
      }
    }
  }
`;

const sharedUpdater = (store, primaryTagId) => {
  const rootField = store.getRoot();
  const viewerField = rootField.getLinkedRecord('viewer');

  const _deleteFromConnection = ([connectionName, filters]) => {
    const connection = ConnectionHandler.getConnection(
      viewerField,
      connectionName,
      filters,
    );
    if (connection) {
      ConnectionHandler.deleteNode(connection, primaryTagId);
    }
  };

  [
    ['TagsViewAll_tags'],
    ['TagsViewCategories_tags'],
    ['TagsViewCurate_tags'],
    ['TagsViewUsed_tags'],
    ['PrimaryTagAutoComplete_suggestionTags', { term: '' }],
    [
      'PrimaryTagAutoComplete_suggestionTags',
      { term: '', filter: { boost: true } },
    ],
    ['CategoryTagAutoComplete_suggestionTagsCategories'],
  ].forEach((connectionName) => _deleteFromConnection(connectionName));

  /** Delete from store */
  store.delete(primaryTagId);
};

export default ({ id }: { id: string }) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticUpdater: (store) => {
        sharedUpdater(store, id);
      },
      updater: (store) => {
        const payload = store.getRootField('deletePrimaryTag');
        const deletedPrimaryTag = payload.getLinkedRecord('primaryTag');
        const error = payload.getLinkedRecord('error');

        if (!error) {
          sharedUpdater(store, deletedPrimaryTag.getValue('id'));
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
