/* @flow */
import React from 'react';
import classNames from 'classnames';

import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';

import styles from './Button3D.module.scss';

type Props = {
  children: React$Node,
  type?: string,
  btnClass?: ?string,
  className?: ?string,
  loading?: boolean,
  success?: boolean,
};
const ICONS = ['fas', 'spinner'];

const Button3D = ({
  btnClass = 'primary',
  className,
  children,
  loading,
  success,
  ...rest
}: Props) => (
  <button
    type="button"
    {...rest}
    className={classNames([
      styles['pl-btn'],
      styles[`pl-btn-${btnClass}`],
      { [styles.success]: success },
      className,
    ])}
  >
    {loading ? <FontAwesomeIcon icon={ICONS} pulse scale="2x" /> : children}
  </button>
);

export default Button3D;
