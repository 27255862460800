/* @flow weak */

import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation rerunUserSearchMutation($input: RerunUserSearchInput!) {
    rerunUserSearch(input: $input) {
      user_search {
        id
        matched_tag {
          id
          title
        }
      }
      error {
        message
      }
    }
  }
`;

const rerunUserSearchMutation = ({ user_search_id }) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        user_search_id,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default rerunUserSearchMutation;
