/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

import LogoutMutation from '../../graphql/mutations/auth/Logout';
import VerifyPinMutation from '../../graphql/mutations/auth/VerifyPinMutation';
import {
  RESET_AUTHENTICATED,
  SET_AUTHENTICATED,
} from '../../redux/reducers/authReducer/authReducer';

import sentryHelpers from '../../utils/sentryHelpers/sentryHelpers';
import store from '../../redux/store';

/** sets the authed value to true in the local storage */
const setAuthed = () => {
  localStorageService.setItem('authed', true);
  store.dispatch({ type: SET_AUTHENTICATED });
};

/** sets the user's data in the local storage */
const setUser = (user) => {
  localStorageService.setItem('user', user);
};

/** sets the user roles data in the local storage */
const setUserRoles = (roles) => {
  localStorageService.setItem('user_roles', roles);
};

/** To be run post authentication to populate the localstorage and update the state of the app based on the user */
const postAuth = ({
  user,
  roles = [],
}: {
  user: { id: string, username: string, name: string },
  roles: Array<string>,
}) => {
  if (!roles.includes('admin')) {
    window.location = 'https://www.plural.com';
  } else {
    setAuthed();
    setUser(user);
    setUserRoles(roles);
    sentryHelpers.setScopeUser(user);
    sentryHelpers.addBreadcrumb({
      category: 'auth',
      message: `Authenticated user ${user.username} id: ${user.id}`,
      level: 'info',
    });
  }
};

/** authenticates a user based on a pin code sent to the user */
const verifyPin = ({
  pin,
  email,
  phone,
  auth_reqid,
}: {
  pin: string,
  email?: ?string,
  phone?: ?string,
  auth_reqid: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    VerifyPinMutation({ pin, email, phone, auth_reqid })
      .then((res) => {
        if (res.verifyPin.error) {
          return resolve({ ...res, success: false });
        }
        postAuth({ user: res.verifyPin.profile, roles: res.verifyPin.roles });
        return resolve({ ...res, success: true });
      })
      .catch(reject);
  });

/** logs the user out from plural and fb and purges the local storage */
const logout = async ({ noBackend } = {}): void => {
  /** Issue logout mutation */
  if (!noBackend) {
    await LogoutMutation();
  }
  // @todo @postmvp remove specific keys?
  localStorageService.clear();
  store.dispatch({ type: RESET_AUTHENTICATED });
  if (window.FB) {
    window.FB.logout();
  }
  /** reset relay env */
  // eslint-disable-next-line global-require
  require('../../graphql/Environment').initEnvironment();
  sentryHelpers.addBreadcrumb({
    category: 'auth',
    message: `Logged out user`,
    level: 'info',
  });
  // @todo proper sentry logout
  sentryHelpers.setScopeUser({
    id: undefined,
    email: undefined,
    username: undefined,
  });
  window.location.reload();
};

/** checks whether the user is authenticated by checking for the token in the local storage */
const isAuthenticated = () =>
  document.cookie.includes('lgi=t') ||
  (localStorageService.getItem('authed') &&
    (window.location.hostname.includes('heroku') ||
      window.location.hostname.includes('netlify') ||
      window.location.hostname.includes('localhost')));

const init = () => {
  /** if the user is already authenticated, dispatch the SET_AUTHENTICATED action to update the store */
  if (isAuthenticated()) {
    store.dispatch({ type: SET_AUTHENTICATED });
  }
};

const Auth = {
  verifyPin,
  logout,
  isAuthenticated,
  init,
};

export default Auth;
