/* @flow */
import React from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';

import styles from './ButtonIconToggle.module.scss';

const ButtonIconToggle = ({
  active,
  className,
  faIcon,
  ...props
}: {
  active: boolean,
  className: string,
  faIcon: string,
}) => (
  <div
    {...props}
    className={classNames([
      styles.button,
      { [styles['button--active']]: active },
      className,
    ])}
  >
    <FontAwesomeIcon icon={faIcon} />
  </div>
);

export default ButtonIconToggle;
