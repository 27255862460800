/* @flow */
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';

import AutoCompleteField from '../AutoCompleteField/AutoCompleteField';

export default createRefetchContainer(
  AutoCompleteField,
  {
    viewer: graphql`
      fragment CategoryTagAutoComplete_viewer on ViewerType
      @argumentDefinitions(
        term: { type: "String", defaultValue: "" }
        first: { type: "Int", defaultValue: 50 }
        type: { type: "TagsSearchType", defaultValue: CATEGORY }
        filter: { type: "TagsFilterType" }
        order_by: { type: "TagsOrderByType" }
      ) {
        suggestionTagsCategories: tags(
          term: $term
          first: $first
          type: $type
          filter: $filter
          order_by: $order_by
        )
          @connection(
            key: "CategoryTagAutoComplete_suggestionTagsCategories"
            filters: ["term"]
          ) {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `,
  },
  graphql`
    query CategoryTagAutoComplete_Refetch_Query(
      $term: String
      $first: Int
      $type: TagsSearchType
      $filter: TagsFilterType
      $order_by: TagsOrderByType
    ) {
      viewer {
        ...CategoryTagAutoComplete_viewer
          @arguments(
            term: $term
            first: $first
            type: $type
            filter: $filter
            order_by: $order_by
          )
      }
    }
  `,
);
