/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './Form.module.scss';

type Props = {
  className?: ?string,
  innerRef?: ?Function,
};

const Form = ({ className, innerRef, ...rest }: Props) => (
  <form
    className={classNames([styles.form, className])}
    ref={innerRef}
    {...rest}
  />
);

export default Form;
