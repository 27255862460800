/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation CreateSkillTagMutation($input: CreateSkillTagInput!) {
    createSkillTag(input: $input) {
      skillTag {
        id
        title
      }
      tagsEdges {
        edges {
          node {
            id
            title
            slug
            remote
            do_not_suggest
            boost
            block
            used_at
            corrected_to_tag {
              id
              title
            }
            categories {
              id
              title
            }
            synonyms {
              id
              title
            }
            skillTags {
              id
              title
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;

const sharedUpdater = (store, skillTag, primaryTagId, newEdges) => {
  const primaryTag = store.get(primaryTagId);
  let primaryTagSkillTag = primaryTag.getLinkedRecords('skillTags');
  primaryTagSkillTag = primaryTagSkillTag.concat(skillTag);
  primaryTag.setLinkedRecords(primaryTagSkillTag, 'skillTags');

  if (!newEdges?.length) {
    return;
  }

  /** Update primary tags */
  const rootField = store.getRoot();
  const viewerField = rootField.getLinkedRecord('viewer');

  const _addToConnection = (connectionName) => {
    const connection = ConnectionHandler.getConnection(
      viewerField,
      connectionName,
    );
    if (connection) {
      newEdges.forEach((newEdge) => {
        ConnectionHandler.insertEdgeBefore(connection, newEdge);
      });
      connection.setValue(
        connection.getValue('totalCount') + newEdges.length,
        'totalCount',
      );
    }
  };

  ['TagsViewAll_tags', 'TagsViewUsed_tags'].forEach((connectionName) =>
    _addToConnection(connectionName),
  );
};

export default ({
  title,
  primaryTagId,
}: {
  title: string,
  primaryTagId: string,
}) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        title,
        primaryTagId,
      },
    };

    commitMutation({
      mutation,
      variables,

      optimisticUpdater: (store) => {
        const id = `OPUP:Tag:${`${Math.random()}`.substring(2, 10)}`;
        const newSkillTag = store.create(id, 'Tag');
        newSkillTag.setValue(id, 'id');
        newSkillTag.setValue(title, 'title');
        newSkillTag.setValue(new Date().toISOString(), 'used_at');
        newSkillTag.setValue(new Date().toISOString(), 'created_at');

        sharedUpdater(store, newSkillTag, primaryTagId);
      },
      updater: (store) => {
        const payload = store.getRootField('createSkillTag');
        const newSkillTag = payload.getLinkedRecord('skillTag');
        const error = payload.getLinkedRecord('error');

        const tagsEdges = payload
          .getLinkedRecord('tagsEdges')
          ?.getLinkedRecords('edges');

        if (!error) {
          sharedUpdater(store, newSkillTag, primaryTagId, tagsEdges);
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
