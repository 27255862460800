/* @flow */
import { combineReducers } from 'redux';

import authReducer from '../authReducer/authReducer';
import appSettingsReducer from '../appSettingsReducer/appSettingsReducer';

export type StateType = {};
export type ActionType = { type: string, payload?: any, [string]: any };

const initialState: StateType = {};

export { initialState };

export default combineReducers({
  appSettings: appSettingsReducer,
  auth: authReducer,
  /** Add more reducers above */
});
