/* @flow */
const ROOT_PREFIX: string = 'APP_SETTINGS/';
const SET_SUBSCRIPTION_CMD_TIMESTAMP = `${ROOT_PREFIX}/SET_SUBSCRIPTION_CMD_TIMESTAMP`;

export type AppSettingsReducerStateType = {
  subscriptionCmdTimestamp: number,
};
const initialState: AppSettingsReducerStateType = {
  /** Subscription command last timestamp - refreshes subscriptions upon change - triggers subscriptions when set */
  subscriptionCmdTimestamp: null,
};

// Actions
export type AppSettingsReducerActionType = { type: string, payload: any };

// Action creators

/**
 * Updates subscriptionCmdTimestamp retriggering subscriptions
 */
export const setSubscriptionCmdTimestamp = (payload: number = Date.now()) => (
  dispatch: Function,
) => {
  dispatch({
    type: SET_SUBSCRIPTION_CMD_TIMESTAMP,
    payload,
  });
};

// Reducer
export default (
  state: AppSettingsReducerStateType = initialState,
  action: AppSettingsReducerActionType,
) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_CMD_TIMESTAMP: {
      return {
        ...state,
        subscriptionCmdTimestamp: action.payload,
      };
    }
    default:
      return state;
  }
};
