/** flow */

let HOST_ENV = process.env.REACT_APP_HOST_ENV;

const setHostEnv = (env) => {
  HOST_ENV = env;
};

const getHostEnv = () => HOST_ENV;

const isDevelopmentLocal = () => HOST_ENV === 'development';

const isDevelopmentRemote = () => HOST_ENV === 'development_remote';

const isSandbox = () => HOST_ENV === 'sandbox';

const isDevelopmentAny = () => isDevelopmentLocal() || isDevelopmentRemote();

const isStaging = () => HOST_ENV === 'staging';

const isProduction = () => HOST_ENV === 'production';

const isRemote = () =>
  isDevelopmentRemote() || isSandbox() || isStaging() || isProduction();

const isTest = () => HOST_ENV === 'test' || process.env.NODE_ENV === 'test';

const isLocalhost = () => window.windowHostname === 'localhost';

const getAppVersion = () => process.env.REACT_APP_VERSION;

const getAppBuildNumber = () =>
  Number(process.env.REACT_APP_BUILD_NUM) || undefined;

const getAppPackageVersion = () => process.env.REACT_APP_PACKAGE_VERSION;

export default {
  setHostEnv,
  getHostEnv,
  isDevelopmentLocal,
  isDevelopmentRemote,
  isDevelopmentAny,
  isSandbox,
  isStaging,
  isProduction,
  isRemote,
  isTest,
  isLocalhost,
  getAppVersion,
  getAppBuildNumber,
  getAppPackageVersion,
};
