/* @flow */
import React from 'react';
import { QueryRenderer } from 'react-relay';

import { getEnvironment } from '../Environment';
import middlewareRender from './middlewareRender';

type Props = {
  render: Function,
  disableMiddleware?: ?boolean,
  variables?: Object,
};

export default ({ render, disableMiddleware, ...rest }: Props) => {
  const environment = getEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      /** Variables need to be passed even if empty as of relay-runtime 1.7 */
      variables={{}}
      render={
        disableMiddleware
          ? render
          : middlewareRender(render, { environment, ...rest })
      }
      {...rest}
    />
  );
};
