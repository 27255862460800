/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation UpdatePaymentRefundMutation($input: UpdatePaymentRefundInput!) {
    updatePaymentRefund(input: $input) {
      payment {
        id
        status
        approved_by_system_at
        refund_request_declined_at
        refund_request_cancelled_at
        refunded_at
        refunded_by
        refunded_by_id
        refund_reason
        is_support_required
      }
      error {
        message
      }
    }
  }
`;

const updatePaymentRefund = (input: {
  payment_id: string,
  refund: boolean,
  refund_reason?: string,
  refund_reject_reason?: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input,
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default updatePaymentRefund;
