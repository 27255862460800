/* @flow */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const AddFaqQuestionMutation = ({
  question_id,
  tag_id,
  question,
  tag_title,
  synonyms = [],
}: {
  question_id: string,
  tag_id: string,
  question: string,
  tag_title: string,
}): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation AddTagToFaqQuestionMutation($input: AddTagToFAQuestionInput!) {
        addTagToFAQuestion(input: $input) {
          error {
            title
            message
            technical_message
          }
          newQuestionTagEdge {
            node {
              id
              hashtag {
                id
                title
                synonyms {
                  id
                  title
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        question_id,
        tag_id,
        question,
        tag_title: tag_id ? null : tag_title,
      },
    };

    const optimisticResponse = {
      addTagToFAQuestion: {
        error: null,
        newQuestionTagEdge: {
          node: {
            id: `OPUP_QuestionTag_${Math.random().toString(36).substr(2, 9)}`,
            hashtag: {
              id: `OPUP_Tag_${Math.random().toString(36).substr(2, 9)}`,
              title: tag_title,
              synonyms,
            },
          },
        },
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: question_id,
        connectionInfo: [
          {
            key: 'Tags_tags',
            rangeBehavior: 'append',
          },
        ],
        edgeName: 'newQuestionTagEdge',
      },
      {
        type: 'RANGE_ADD',
        parentID: question_id,
        connectionInfo: [
          {
            key: 'SynonymsTags_synonymsTags',
            rangeBehavior: 'append',
          },
        ],
        edgeName: 'newQuestionTagEdge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default AddFaqQuestionMutation;
