import { useLayoutEffect } from 'react';

/**
 * Provide simple hook to handle outside component click
 * */

const useOutSideClick = (onClick, eventName = 'click') => {
  useLayoutEffect(() => {
    window.addEventListener(eventName, onClick);
    return () => window.removeEventListener(eventName, onClick);
    /** Prevent multiple rerender */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOutSideClick;
