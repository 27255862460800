/* @flow */
import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { Button, List } from 'antd';
import TextField from '@material-ui/core/TextField';
import {
  CreateCategoryTagMutation,
  CreatePrimaryTagMutation,
  CreateSynonymTagMutation,
  DeletePrimaryTagMutation,
  RemoveSynonymCategoryFromPrimaryTagMutation,
  UpdatePrimaryTagMutation,
  CreateSkillTagMutation,
  CorrectTagTitleMutation,
} from '../../graphql/mutations';
import Navbar from '../../containers/Navbar/Navbar';
import Spinner from '../Spinner/Spinner';
import { useRelayLoadMore } from '../../hooks';
import { logger } from '../../utils';

import {
  PrimaryTag,
  PrimaryTagAutoComplete,
  CategoryTagAutoComplete,
} from './components';

import type { PrimaryTag_primaryTag } from './components/PrimaryTag/__generated__/PrimaryTag_primaryTag.graphql';

import styles from './TagsView.module.scss';

const DEBOUNCE_REFETCH_TIME = 500;
const INITIAL_COUNT = 100;

type Props = {
  viewer: {
    tags: ?{
      edges: Array<{
        node: PrimaryTag_primaryTag,
      }>,
    },
  },
  relay: Object,
  relayVariables: {
    type: string,
    filter?: { boost?: boolean, used?: boolean },
    order_by?: string,
  },
  queryType?: 'all' | 'categories' | 'curate' | 'used', // defaults to all
};

const _addPrimaryTag = (tagColumns?: Object) => (title: string) =>
  CreatePrimaryTagMutation({
    title,
    ...tagColumns,
  });

const _editPrimaryTag = (input, { primaryTag } = {}) =>
  UpdatePrimaryTagMutation(input, { primaryTag });

const _addCategory = (categoryTitle, primaryTagId, categoryTagId?: string) =>
  CreateCategoryTagMutation(
    {
      title: categoryTitle,
      primaryTagId,
    },
    {
      categoryTagId,
    },
  );

const _removeCategory = (categoryTagId, primaryTagId) =>
  RemoveSynonymCategoryFromPrimaryTagMutation({
    primaryTagId,
    categoryTagIds: [categoryTagId],
  });

const _addSynonym = (synonymTitle, primaryTagId) =>
  CreateSynonymTagMutation({
    title: synonymTitle,
    primaryTagId,
  });

const _removeSynonym = (synonymTagId, primaryTagId) =>
  RemoveSynonymCategoryFromPrimaryTagMutation({
    primaryTagId,
    synonymTagIds: [synonymTagId],
  });

const _addSkillTag = (title, primaryTagId) =>
  CreateSkillTagMutation({
    title,
    primaryTagId,
  });

const _removeSkillTag = (synonymTagId, primaryTagId) =>
  RemoveSynonymCategoryFromPrimaryTagMutation({
    primaryTagId,
    skillTagIds: [synonymTagId],
  });

const _deletePrimaryTag = (id) => DeletePrimaryTagMutation({ id });

const _correctTagTitle = (tagId, correctedToTag) =>
  CorrectTagTitleMutation({ tagId, correctedToTag });

const _refetchConnection = ({ relay, term, relayVariables }) =>
  relay.refetchConnection(
    INITIAL_COUNT,
    (err) => {
      if (err) {
        logger.debugError('Error in Tags view: refetchConnection', err);
      }
    },
    { term, ...relayVariables },
    {
      /** Attempt fetch data from store first to avoid useless reload */
      fetchPolicy: 'store-or-network',
      force: true,
    },
  );

const _refetchConnectionDebounced = debounce(
  _refetchConnection,
  DEBOUNCE_REFETCH_TIME,
);

const _handleChangeSearchText = ({ setSearchTerm, relay, relayVariables }) => (
  event,
) => {
  setSearchTerm(event.target.value);
  _refetchConnectionDebounced({
    relay,
    term: event.target.value,
    relayVariables,
  });
};

/**
 * Generic Tags Table
 */
const TagsView = ({ viewer, relay, queryType, relayVariables }: Props) => {
  const { tags: { edges: primaryTags, totalCount } = {} } = viewer;

  const [searchTerm, setSearchTerm] = useState('');
  const relayHook = useRelayLoadMore({ relay });

  return (
    <>
      <section>
        <Navbar>
          <TextField
            placeholder="Search..."
            value={searchTerm}
            onChange={_handleChangeSearchText({
              relay,
              setSearchTerm,
              relayVariables,
            })}
          />
          {queryType === 'categories' ? (
            <>
              <span className={styles.space} />
              <CategoryTagAutoComplete
                viewer={viewer}
                onSubmit={_addCategory}
                placeholder="Add Category Tag..."
                size="default"
              />
            </>
          ) : null}
          <span className={styles.space} />
          <PrimaryTagAutoComplete
            viewer={viewer}
            onSubmit={_addPrimaryTag({
              ...(queryType === 'all' ? {} : { boost: true }),
            })}
            placeholder={`Add ${
              queryType === 'all' ? 'Primary' : 'Boosted'
            } Tag...`}
            size="default"
          />
        </Navbar>
      </section>

      <section className={styles.content}>
        <List
          size="middle"
          pagination={{
            defaultPageSize: 50,
            showTotal: () => totalCount,
          }}
          className={styles.list}
          header={
            <PrimaryTag isHeader primaryTag={null} queryType={queryType} />
          }
          dataSource={primaryTags?.filter((el) => el.node)}
          renderItem={(primaryTag) => (
            <List.Item className={styles.list_item}>
              <PrimaryTag
                viewer={viewer}
                primaryTag={primaryTag.node}
                editPrimaryTag={_editPrimaryTag}
                addCategory={_addCategory}
                removeCategory={_removeCategory}
                addSynonym={_addSynonym}
                removeSynonym={_removeSynonym}
                addSkillTag={_addSkillTag}
                removeSkillTag={_removeSkillTag}
                deletePrimaryTag={_deletePrimaryTag}
                correctTagTitle={_correctTagTitle}
                queryType={queryType}
                key={primaryTag.node.__id}
              />
            </List.Item>
          )}
        />
        <div>
          <Button
            className={styles.btnLoadMore}
            onClick={() => relayHook.loadMore(100)}
            disabled={relayHook.isLoadingMore || !relay.hasMore()}
          >
            {relayHook.isLoadingMore ? (
              <Spinner className={styles.spinner} />
            ) : (
              'Load More'
            )}
          </Button>
        </div>
      </section>
    </>
  );
};

export default TagsView;
