/**
 * @generated SignedSource<<ee295548306f4290e5bdbe381c2a03dd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateSynonymTagInput = {|
  title: string,
  primaryTagId: string,
  clientMutationId?: ?string,
|};
export type CreateSynonymTagMutation$variables = {|
  input: CreateSynonymTagInput,
|};
export type CreateSynonymTagMutation$data = {|
  +createSynonymTag: ?{|
    +synonymTag: ?{|
      +id: string,
      +title: ?string,
    |},
    +tagsEdges: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
          +slug: ?string,
          +remote: ?boolean,
          +do_not_suggest: ?boolean,
          +boost: ?boolean,
          +block: ?boolean,
          +used_at: ?any,
          +corrected_to_tag: ?{|
            +id: string,
            +title: ?string,
          |},
          +categories: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +synonyms: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +skillTags: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
        |},
      |}>,
    |},
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type CreateSynonymTagMutation = {|
  variables: CreateSynonymTagMutation$variables,
  response: CreateSynonymTagMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "synonymTag",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TagConnection",
  "kind": "LinkedField",
  "name": "tagsEdges",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "do_not_suggest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "block",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "used_at",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "corrected_to_tag",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "categories",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "synonyms",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "skillTags",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSynonymTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSynonymTagPayload",
        "kind": "LinkedField",
        "name": "createSynonymTag",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSynonymTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSynonymTagPayload",
        "kind": "LinkedField",
        "name": "createSynonymTag",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e908dec0305b8575b66752f2237f1e6f",
    "id": null,
    "metadata": {},
    "name": "CreateSynonymTagMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSynonymTagMutation(\n  $input: CreateSynonymTagInput!\n) {\n  createSynonymTag(input: $input) {\n    synonymTag {\n      id\n      title\n    }\n    tagsEdges {\n      edges {\n        node {\n          id\n          title\n          slug\n          remote\n          do_not_suggest\n          boost\n          block\n          used_at\n          corrected_to_tag {\n            id\n            title\n          }\n          categories {\n            id\n            title\n          }\n          synonyms {\n            id\n            title\n          }\n          skillTags {\n            id\n            title\n          }\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "04e84f91402a5e2dd07522ebb64519e4";

module.exports = ((node/*: any*/)/*: Mutation<
  CreateSynonymTagMutation$variables,
  CreateSynonymTagMutation$data,
>*/);
