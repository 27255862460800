/* @flow */
import React from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './Spinner.module.scss';

type Props = {
  className?: ?string,
};

const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spinner = ({ className, ...rest }: Props) => (
  <span className={classNames([styles.container, className])} {...rest}>
    <Spin indicator={icon} />
  </span>
);

export default Spinner;
