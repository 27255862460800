/* @flow */
import { Tag, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import moment from 'moment';
import copyToClipboard from 'copy-to-clipboard';

import ButtonIconToggle from '../../../ButtonIconToggle/ButtonIconToggle';

import PrimaryTagAutoComplete from '../PrimaryTagAutoComplete/PrimaryTagAutoComplete';
import CategoryTagAutoComplete from '../CategoryTagAutoComplete/CategoryTagAutoComplete';

import { EmojiInput } from './components';

import styles from './PrimaryTag.module.scss';

import type { PrimaryTag_primaryTag } from './__generated__/PrimaryTag_primaryTag.graphql';
import { logger } from '../../../../utils';

type Props = {
  primaryTag: PrimaryTag_primaryTag,
  isHeader?: ?Boolean,
  viewer: Object,
  editPrimaryTag: (input) => Promise<Object>,
  addCategory: (string, string) => any,
  removeCategory: (string, string) => any,
  addSynonym: (string, string) => any,
  removeSynonym: (string, string) => any,
  addSkillTag: (string, string) => any,
  removeSkillTag: (string, string) => any,
  correctTagTitle: (string, string) => any,
  queryType?: 'all' | 'categories' | 'curate' | 'used', // defaults to all
};

const TIMESTAMP_FORMAT = 'YY-MM-DD hh:mm:ss';

/** Checks whether a tag is touched to show alternate style */
const _isTouchedTag = (primaryTag: PrimaryTag_primaryTag): Boolean =>
  Boolean(
    primaryTag.block ||
      primaryTag.boost ||
      primaryTag.do_not_suggest ||
      primaryTag.remote ||
      primaryTag.corrected_to_tag ||
      primaryTag.categories?.length ||
      primaryTag.synonyms?.length ||
      primaryTag.skillTags?.length,
  );

const globeIcon = ['fas', 'globe'];
const starIcon = ['fas', 'star'];
const exclamationIcon = ['fas', 'exclamation-triangle'];
const timesIcon = ['fas', 'times'];

/* next few functions are the run time equiv of relay fragment arguments */
const _isAll = (queryType) => queryType === 'all';
const _isAllOrCurate = (queryType) => ['all', 'curate'].includes(queryType);
const _isAllOrUsed = (queryType) => ['all', 'used'].includes(queryType);
const _isAllOrCurateOrUsed = (queryType) =>
  ['all', 'curate', 'used'].includes(queryType);
const _isAllOrCategories = (queryType) =>
  ['all', 'categories'].includes(queryType);
const _isCategories = (queryType) => queryType === 'categories';

/**
 * PrimaryTag generic component
 *
 * Notes:
 * - notice how we conditionally render fields based on the queryType run time variable AND the relay arguments provided below
 */
const PrimaryTag = ({
  primaryTag,
  editPrimaryTag,
  isHeader,
  viewer,
  queryType = 'all',
  ...props
}: Props) => {
  const [inputVisibleCategory, setInputVisibleCategory] = useState(false);
  const [inputVisibleSynonym, setInputVisibleSynonym] = useState(false);
  const [inputVisibleSkillTags, setInputVisibleSkillTags] = useState(false);
  const [inputVisibleCorrectedTo, setInputVisibleCorrectedTo] = useState(false);
  const [
    inputVisibleTagsWithThisCategory,
    setInputVisibleTagsWithThisCategory,
  ] = useState(false);
  const [isDisabledCheckboxSmart, setDisabledCheckboxSmart] = useState(false);

  /* render header */
  if (isHeader) {
    return (
      <div className={styles.row_header}>
        <span className={styles.id}>ID</span>
        {_isAllOrCurateOrUsed(queryType) && (
          <span className={styles.flags}>Flags</span>
        )}
        <span className={styles.title_header}>Title</span>
        {_isAllOrUsed(queryType) && (
          <span className={styles.correct_title_to}>Corrected To</span>
        )}
        {_isAllOrCategories(queryType) && (
          <span className={styles.emoji_header}>Emoji</span>
        )}
        {_isCategories(queryType) && (
          <span className={styles.is_smart_checkbox_header}>Smart</span>
        )}
        {_isAllOrCurate(queryType) && (
          <>
            <span className={styles.categories}>Categories</span>
            <span className={styles.synonyms}>Synonyms</span>
            <span className={styles.synonyms}>Skill Tags</span>
          </>
        )}
        {_isCategories(queryType) && (
          <span className={styles.categories}>Tags in this category</span>
        )}
        {_isAllOrCurateOrUsed(queryType) && (
          <span className={styles.timestamp}>Used at</span>
        )}
        {_isAll(queryType) && (
          <span className={styles.timestamp}>Created at</span>
        )}
      </div>
    );
  }

  // Handle Category Changes

  const _addCategory = (primaryTagId, value, categoryTagId?: string) => {
    if (value.length) {
      props.addCategory(value, primaryTagId, categoryTagId);
    }
    setInputVisibleCategory(false);
  };

  // Handle Synonym Changes

  const _addSynonym = (primaryTagId, value) => {
    if (value.length) {
      props.addSynonym(value, primaryTagId);
    }
    setInputVisibleSynonym(false);
  };

  // Handle Skill Tag Changes

  const _addSkillTag = (primaryTagId, value) => {
    if (value.length) {
      props.addSkillTag(value, primaryTagId);
    }
    setInputVisibleSkillTags(false);
  };

  const _toggleFlag = (flagName) => () =>
    editPrimaryTag(
      {
        id: primaryTag.id,
        [flagName]: !primaryTag[flagName],
      },
      { primaryTag },
    );

  const _onToggleCheckboxSmart = async () => {
    /* Make checkbox unclickable while backend 
       updates the tag */
    setDisabledCheckboxSmart(true);
    try {
      await editPrimaryTag(
        {
          id: primaryTag.id,
          is_smart: !primaryTag.is_smart,
        },
        { primaryTag },
      );
    } catch (err) {
      logger.error('Error: editPrimaryTag ', err);
    }
    setDisabledCheckboxSmart(false);
  };

  return (
    <div
      className={!_isTouchedTag(primaryTag) ? styles.row : styles.row_alternate}
    >
      {/* ID */}
      <div
        className={classNames([styles.id, styles.rtl])}
        onDoubleClick={() => {
          copyToClipboard(primaryTag.id);
        }}
      >
        {primaryTag.id}
      </div>

      {/* Flags */}
      {_isAllOrCurateOrUsed(queryType) && (
        <div className={styles.flags}>
          <ButtonIconToggle
            className={styles['btn-flag']}
            faIcon={globeIcon}
            active={primaryTag.remote}
            onClick={_toggleFlag('remote')}
          />
          <ButtonIconToggle
            className={styles['btn-flag']}
            faIcon={starIcon}
            active={primaryTag.boost}
            onClick={_toggleFlag('boost')}
          />
          <ButtonIconToggle
            className={styles['btn-flag']}
            faIcon={exclamationIcon}
            active={primaryTag.do_not_suggest}
            onClick={_toggleFlag('do_not_suggest')}
          />
          <ButtonIconToggle
            className={styles['btn-flag']}
            faIcon={timesIcon}
            active={primaryTag.block}
            onClick={_toggleFlag('block')}
          />
        </div>
      )}

      {/* Tag Title */}
      <div className={styles.title}>{primaryTag.title}</div>

      {/* Tag Corrected To Tag Title */}
      {_isAllOrUsed(queryType) && (
        <div className={styles.correct_title_to}>
          {inputVisibleCorrectedTo || primaryTag.corrected_to_tag ? (
            <PrimaryTagAutoComplete
              size="small"
              viewer={viewer}
              onSubmit={(tagTitle, tagId) =>
                props.correctTagTitle(primaryTag.id, {
                  id: tagId,
                  title: tagTitle,
                })
              }
              hide={() => setInputVisibleCorrectedTo(false)}
              defaultFocus={!primaryTag.corrected_to_tag?.title}
              defaultValue={primaryTag.corrected_to_tag?.title}
            />
          ) : (
            <Tag
              onClick={() => setInputVisibleCorrectedTo(true)}
              className={styles.tag_plus}
            >
              <PlusOutlined className={styles['tag-icon']} /> Correct title to
            </Tag>
          )}
        </div>
      )}

      {/* Tag Emoji */}
      {_isAllOrCategories(queryType) && (
        <div className={styles.emoji}>
          <EmojiInput
            value={primaryTag.emoji || ''}
            primaryTag={primaryTag}
            editPrimaryTag={editPrimaryTag}
            tagClassname={classNames([
              { [styles.tag_plus]: !primaryTag.emoji },
            ])}
          />
        </div>
      )}

      {/* Smart Category */}
      {_isCategories(queryType) && (
        <div className={styles.is_smart_checkbox}>
          <Checkbox
            disabled={isDisabledCheckboxSmart}
            checked={primaryTag.is_smart}
            onChange={_onToggleCheckboxSmart}
          />
        </div>
      )}

      {/* Categories */}
      {_isAllOrCurate(queryType) && (
        <>
          <div className={styles.categories}>
            {primaryTag.categories?.map((category) => (
              <Tag
                className={styles.tag}
                key={category.id}
                closable
                onClose={() => props.removeCategory(category.id, primaryTag.id)}
              >
                {category.title}
              </Tag>
            ))}
            {inputVisibleCategory ? (
              <CategoryTagAutoComplete
                size="small"
                viewer={viewer}
                onSubmit={(value) => _addCategory(primaryTag.id, value)}
                hide={() => setInputVisibleCategory(false)}
                defaultFocus
                disableValueSubmission
              />
            ) : (
              <Tag
                onClick={() => setInputVisibleCategory(true)}
                className={styles.tag_plus}
              >
                <PlusOutlined className={styles['tag-icon']} /> Add Category
              </Tag>
            )}
          </div>

          {/* Synonyms */}
          <div className={styles.synonyms}>
            {primaryTag.synonyms?.map((synonym) => (
              <Tag
                className={styles.tag}
                key={synonym.id}
                closable
                onClose={() => props.removeSynonym(synonym.id, primaryTag.id)}
              >
                {synonym.title}
              </Tag>
            ))}
            {inputVisibleSynonym ? (
              <PrimaryTagAutoComplete
                size="small"
                viewer={viewer}
                onSubmit={(value) => _addSynonym(primaryTag.id, value)}
                defaultFocus
                hide={() => setInputVisibleSynonym(false)}
              />
            ) : (
              <Tag
                onClick={() => setInputVisibleSynonym(true)}
                className={styles.tag_plus}
              >
                <PlusOutlined className={styles['tag-icon']} /> Add Synonym
              </Tag>
            )}
          </div>

          {/* Skill Tags */}
          <div className={styles.synonyms}>
            {primaryTag.skillTags?.map((skillTag) => (
              <Tag
                className={styles.tag}
                key={skillTag.id}
                closable
                onClose={() => props.removeSkillTag(skillTag.id, primaryTag.id)}
              >
                {skillTag.title}
              </Tag>
            ))}
            {inputVisibleSkillTags ? (
              <PrimaryTagAutoComplete
                size="small"
                viewer={viewer}
                onSubmit={(value) => _addSkillTag(primaryTag.id, value)}
                hide={() => setInputVisibleSkillTags(false)}
                defaultFocus
              />
            ) : (
              <Tag
                onClick={() => setInputVisibleSkillTags(true)}
                className={styles.tag_plus}
              >
                <PlusOutlined className={styles['tag-icon']} /> Add Skill Tag
              </Tag>
            )}
          </div>
        </>
      )}

      {/* Tags in this category */}
      {_isCategories(queryType) && (
        <div className={styles.tags_with_this_category}>
          {primaryTag.tags_with_this_category?.map((tagWithThisCategory) => (
            <Tag
              className={styles.tag}
              key={tagWithThisCategory.id}
              closable
              onClose={() =>
                props.removeCategory(primaryTag.id, tagWithThisCategory.id)
              }
            >
              {tagWithThisCategory.title}
            </Tag>
          ))}
          {inputVisibleTagsWithThisCategory ? (
            <PrimaryTagAutoComplete
              size="small"
              viewer={viewer}
              onSubmit={(_, selectedTagId) =>
                _addCategory(selectedTagId, primaryTag.title, primaryTag.id)
              }
              hide={() => setInputVisibleTagsWithThisCategory(false)}
              defaultFocus
              disableValueSubmission
            />
          ) : (
            <Tag
              onClick={() => setInputVisibleTagsWithThisCategory(true)}
              className={styles.tag_plus}
            >
              <PlusOutlined className={styles['tag-icon']} /> Add Tag
            </Tag>
          )}
        </div>
      )}

      {/* timestamps */}
      {_isAllOrCurateOrUsed(queryType) && (
        <div className={styles.timestamp}>
          {primaryTag.used_at
            ? moment(primaryTag.used_at).format(TIMESTAMP_FORMAT)
            : '-'}
        </div>
      )}
      {_isAll(queryType) && (
        <div className={styles.timestamp}>
          {primaryTag.created_at
            ? moment(primaryTag.created_at).format(TIMESTAMP_FORMAT)
            : '-'}
        </div>
      )}
    </div>
  );
};

export { PrimaryTag as PurePrimaryTag };

export default createFragmentContainer(PrimaryTag, {
  primaryTag: graphql`
    fragment PrimaryTag_primaryTag on Tag
    @argumentDefinitions(
      isAll: { type: "Boolean", defaultValue: true }
      isAllOrCurate: { type: "Boolean", defaultValue: true }
      isAllOrCategories: { type: "Boolean", defaultValue: true }
      isAllOrCurateOrUsed: { type: "Boolean", defaultValue: true }
      isAllOrUsed: { type: "Boolean", defaultValue: true }
      isCategories: { type: "Boolean", defaultValue: false }
    ) {
      id
      title
      slug
      emoji @include(if: $isAllOrCategories)
      is_smart @include(if: $isCategories)
      remote @include(if: $isAllOrCurateOrUsed)
      do_not_suggest @include(if: $isAllOrCurateOrUsed)
      boost @include(if: $isAllOrCurateOrUsed)
      block @include(if: $isAllOrCurateOrUsed)
      used_at @include(if: $isAllOrCurateOrUsed)
      corrected_to_tag @include(if: $isAllOrUsed) {
        id
        title
      }
      categories @include(if: $isAllOrCurate) {
        id
        title
      }
      synonyms @include(if: $isAllOrCurate) {
        id
        title
      }
      skillTags @include(if: $isAllOrCurate) {
        id
        title
      }
      tags_with_this_category @include(if: $isCategories) {
        id
        title
      }
      created_at @include(if: $isAll)
    }
  `,
});
