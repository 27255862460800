/* @flow */

const getDeviceInfoHeader = () => {
  const values = {
    appVersion: process.env.REACT_APP_VERSION || '1.0.0',
    appBuildNo: (process.env.REACT_APP_VERSION || '1.0.0').split('.')[3] || 0,
    // Important to differentiate between web and iOS and android apps
    sysName: 'web',
  };
  return Object.keys(values).reduce(
    (str, key) => `${str ? `${str}|` : ''}${key}/${values[key]}`,
    '',
  );
};

export default getDeviceInfoHeader;
