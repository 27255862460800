/**
 * @generated SignedSource<<128ca6bdf8e2b83d29933d3b74e8fbe7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CorrectTagTitleInput = {|
  old_tag_id: string,
  new_tag_id?: ?string,
  new_title?: ?string,
  clientMutationId?: ?string,
|};
export type CorrectTagTitleMutation$variables = {|
  input: CorrectTagTitleInput,
|};
export type CorrectTagTitleMutation$data = {|
  +correctTagTitle: ?{|
    +oldTag: ?{|
      +id: string,
      +corrected_to_tag: ?{|
        +id: string,
        +title: ?string,
      |},
    |},
    +tagsEdges: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
          +slug: ?string,
          +remote: ?boolean,
          +do_not_suggest: ?boolean,
          +boost: ?boolean,
          +block: ?boolean,
          +used_at: ?any,
          +corrected_to_tag: ?{|
            +id: string,
            +title: ?string,
          |},
          +categories: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +synonyms: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +skillTags: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +tags_with_this_category: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
        |},
      |}>,
    |},
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type CorrectTagTitleMutation = {|
  variables: CorrectTagTitleMutation$variables,
  response: CorrectTagTitleMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "corrected_to_tag",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "oldTag",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "TagConnection",
  "kind": "LinkedField",
  "name": "tagsEdges",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "do_not_suggest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "block",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "used_at",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "categories",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "synonyms",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "skillTags",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "tags_with_this_category",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorrectTagTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CorrectTagTitlePayload",
        "kind": "LinkedField",
        "name": "correctTagTitle",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorrectTagTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CorrectTagTitlePayload",
        "kind": "LinkedField",
        "name": "correctTagTitle",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "854b74587bba8f9112d06bbce8bde517",
    "id": null,
    "metadata": {},
    "name": "CorrectTagTitleMutation",
    "operationKind": "mutation",
    "text": "mutation CorrectTagTitleMutation(\n  $input: CorrectTagTitleInput!\n) {\n  correctTagTitle(input: $input) {\n    oldTag {\n      id\n      corrected_to_tag {\n        id\n        title\n      }\n    }\n    tagsEdges {\n      edges {\n        node {\n          id\n          title\n          slug\n          remote\n          do_not_suggest\n          boost\n          block\n          used_at\n          corrected_to_tag {\n            id\n            title\n          }\n          categories {\n            id\n            title\n          }\n          synonyms {\n            id\n            title\n          }\n          skillTags {\n            id\n            title\n          }\n          tags_with_this_category {\n            id\n            title\n          }\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "24901ff2f8b3ed02e04a3ad60e93ad26";

module.exports = ((node/*: any*/)/*: Mutation<
  CorrectTagTitleMutation$variables,
  CorrectTagTitleMutation$data,
>*/);
