/* eslint global-require: 0 import/no-extraneous-dependencies: 0 */

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { localStorageService } from '@pluralcom/plural-web-utils';

import { environment } from '../utils';
import rootReducer, { initialState } from './reducers/rootReducer/rootReducer';

const REDUX_DEV_MODE =
  environment.isDevelopmentAny() || localStorageService.getItem('dev_redux');

/** local configurations of the file */
const config = {
  withDevTools: false,
  withLogger: true,
};

let enhancers = [];
let middleware = [thunk];

/**
 * Add development middlewares and enhancers
 */
if (REDUX_DEV_MODE) {
  // const { composeWithDevTools } = require('redux-devtools-extension');
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function' && config.withDevTools) {
    enhancers = enhancers.concat(devToolsExtension());
  }
  if (config.withLogger) {
    const { logger } = require('redux-logger');
    middleware = middleware.concat(logger);
  }
}

/**
 * Redux' composed enhancers and middleware
 */
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

/**
 * Redux store
 */
const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
