/* @flow */
import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from '../../graphql';

import Navbar from '../../components/Navbar/Navbar';

const NavbarContainer = ({ ...rest }) => (
  <QueryRenderer
    fetchPolicy="store-or-network"
    query={graphql`
      query NavbarQuery {
        viewer {
          ...Navbar_viewer
        }
      }
    `}
    render={({ props: data }) => (
      <Navbar viewer={data && data.viewer} {...rest} />
    )}
  />
);

export default NavbarContainer;
