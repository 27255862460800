/* @flow */

import { inputsMaxLengths as globalInputsMaxLengths } from '@pluralcom/plural-js-utils/lib/inputsLengths';

/**
 * Maximum lengths for different input fields.
 */
const inputsMaxLengths: { [field: string]: number } = {
  // global
  ...globalInputsMaxLengths,
  // override
  tagTitle: 30,
};

export default inputsMaxLengths;
