/* @flow */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import intersection from 'lodash/intersection';
import { localStorageService } from '@pluralcom/plural-web-utils';

/** Default authentication route to be routed to */
const DEFAULT_AUTH_ROUTE = '/login';

/**
 * Protected Route Component has the exact API as React-Router's Browser Router.
 * Except that if the User is not authenticated, they will be redirected to the auth page
 * With the redirection, information about the protected route is passed
 * to the auth page to route to it post auth
 */
const ProtectedRoute = ({
  component: Comp,
  render,
  redirectTo = {
    pathname: DEFAULT_AUTH_ROUTE,
  },
  disableRedirect,
  accessRoles: inAccessRoles = ['user'],
  ...rest
}: {
  component?: React$Component<*, *, *>,
  render?: Function,
  redirectTo:
    | ?{
        pathname: string,
        state?: ?Object,
      }
    | ?string,
  disableRedirect?: ?boolean,
  accessRoles: Array<string>,
}) => (
  <Route
    {...rest}
    render={(routProps: { location: Location }) => {
      const { location } = routProps;
      const accessRoles = inAccessRoles.filter(Boolean);
      if (
        intersection(
          accessRoles,
          localStorageService.getItem('user_roles') || [],
        ).length !== accessRoles.length &&
        !disableRedirect
      ) {
        let to = {
          pathname:
            typeof redirectTo === 'string' ? redirectTo : DEFAULT_AUTH_ROUTE,
          state: { from: location },
        };
        if (typeof redirectTo === 'object') {
          to = {
            ...to,
            ...redirectTo,
          };
        }
        return <Redirect to={to} />;
      }
      if (Comp) {
        return <Comp {...routProps} />;
      }
      if (render) {
        return render(routProps);
      }
      return new Error(
        'No component or render function have been provided. Please make sure to pass at least one.',
      );
    }}
  />
);

export { ProtectedRoute as PureProtectedRoute };

export default ProtectedRoute;
