import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RelayEnvironmentProvider } from 'react-relay';

import { getEnvironment } from './graphql/Environment';
import reduxStore from './redux/store';
import { ProtectedRoute, SiteWrapper } from './components';
import LoadingLayout from './components/LoadingLayout/LoadingLayout';
import { browserHistory, sentryHelpers, lazyLoadRetry } from './utils';
import Auth from './services/Auth';

import './App.scss';

const TagsViewAll = lazy(() =>
  lazyLoadRetry(() => import('./screens/TagsViewAll/TagsViewAll')),
);
const TagsViewCategories = lazy(() =>
  lazyLoadRetry(() =>
    import('./screens/TagsViewCategories/TagsViewCategories'),
  ),
);
const TagsViewCurate = lazy(() =>
  lazyLoadRetry(() => import('./screens/TagsViewCurate/TagsViewCurate')),
);
const TagsViewUsed = lazy(() =>
  lazyLoadRetry(() => import('./screens/TagsViewUsed/TagsViewUsed')),
);
const Metrics = lazy(() =>
  lazyLoadRetry(() => import('./screens/Metrics/Metrics')),
);
const Tools = lazy(() => lazyLoadRetry(() => import('./screens/Tools/Tools')));
const Users = lazy(() => lazyLoadRetry(() => import('./screens/Users/Users')));
const AddBetaUsers = lazy(() =>
  lazyLoadRetry(() => import('./screens/AddBetaUsers/AddBetaUsers')),
);
const UserSearches = lazy(() =>
  lazyLoadRetry(() => import('./screens/UserSearches/UserSearches')),
);
const Skills = lazy(() =>
  lazyLoadRetry(() => import('./screens/Skills/Skills')),
);
const Payments = lazy(() =>
  lazyLoadRetry(() => import('./screens/Payments/Payments')),
);
const Alerts = lazy(() =>
  lazyLoadRetry(() => import('./screens/Alerts/Alerts')),
);
const PluralBot = lazy(() =>
  lazyLoadRetry(() => import('./screens/PluralBot/PluralBot')),
);
const Login = lazy(() => lazyLoadRetry(() => import('./screens/Login/Login')));
const VerifyCode = lazy(() =>
  lazyLoadRetry(() => import('./screens/VerifyCode/VerifyCode')),
);
const Home = lazy(() => lazyLoadRetry(() => import('./screens/Home/Home')));

const Faqs = lazy(() => lazyLoadRetry(() => import('./screens/Faqs/Faqs')));

const Base64 = lazy(() =>
  lazyLoadRetry(() => import('./screens/Base64/Base64')),
);

Auth.init();
sentryHelpers.init();

const App = () => (
  <Provider store={reduxStore}>
    <Router history={browserHistory}>
      <Suspense fallback={<LoadingLayout />}>
        <RelayEnvironmentProvider environment={getEnvironment()}>
          <SiteWrapper>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/verify-code" component={VerifyCode} />

              <Route path="/base64" component={Base64} />

              {/* Protected Routes */}
              <ProtectedRoute path="/metrics" component={Metrics} />
              <ProtectedRoute path="/tools" component={Tools} />
              <ProtectedRoute
                path="/tags/categories"
                component={TagsViewCategories}
              />
              <ProtectedRoute path="/tags/curate" component={TagsViewCurate} />
              <ProtectedRoute path="/tags/used" component={TagsViewUsed} />
              <ProtectedRoute path="/tags/all" component={TagsViewAll} />
              <ProtectedRoute path="/faqs" component={Faqs} />
              <ProtectedRoute path="/metrics" component={Metrics} />
              <ProtectedRoute path="/tools" component={Tools} />
              <ProtectedRoute path="/users/add-beta" component={AddBetaUsers} />
              <ProtectedRoute path="/users" component={Users} />
              <ProtectedRoute path="/user-searches" component={UserSearches} />
              <ProtectedRoute path="/skills" component={Skills} />
              <ProtectedRoute path="/payments" component={Payments} />
              <ProtectedRoute path="/alerts" component={Alerts} />
              <ProtectedRoute path="/plural-bot" component={PluralBot} />

              {/* 404 Not Found */}
              <Route render={() => <div>404 Not Found</div>} />
            </Switch>
          </SiteWrapper>
        </RelayEnvironmentProvider>
      </Suspense>
    </Router>
  </Provider>
);

export default App;
