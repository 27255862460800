import { isLoggedOut } from '@pluralcom/plural-js-utils/lib/authHelpers';
import { logger, sentryHelpers } from '../../utils';

/**
 * Sentry handler
 */
const sentryHandler = (error) => {
  sentryHelpers.addBreadcrumb({
    level: 'error',
    category: 'graphql_error_query_response',
    message: 'Query Renderer Error response',
    data: error,
  });

  if (isLoggedOut(error)) {
    return sentryHelpers.captureEvent({
      level: 'warning',
      message: 'User logged out',
      data: error,
    });
  }

  return sentryHelpers.captureException(error);
};

const middlewareRender = (render, queryRendererProps) => {
  if (render) {
    return ({ error, props }) => {
      if (error) {
        logger.debugError(
          'Query Renderer error',
          { error, props },
          {
            queryRendererProps: {
              ...queryRendererProps,
              render,
            },
          },
        );
        sentryHandler(error);
      }
      return render({ error, props });
    };
  }
  return render;
};

export default middlewareRender;
