/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation VerifyPinMutation($input: VerifyPinInput!) {
    verifyPin(input: $input) {
      profile {
        id
        username
        name
        first_name
        last_name
        avatar {
          id
          smallThumbnail
          # For mixpanel
          thumbnail
        }
        email
        phone
        # For mixpanel
        website
        # For mixpanel
        gender
        # For mixpanel
        birthdate
        # For mixpanel
        created_at
        # For mixpanel
        published_at
      }
      roles
      error {
        message
      }
    }
  }
`;

export default ({
  pin,
  email,
  phone,
  auth_reqid,
}:
  | {
      pin: string,
      auth_reqid: string,
      email: string,
    }
  | {
      pin: string,
      auth_reqid: string,
      phone: string,
    }): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        pin,
        email,
        phone,
        with_auth: true,
        auth_reqid,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });
