/* @flow */
import {
  addBreadcrumb,
  captureEvent,
  captureException,
  captureMessage,
  configureScope,
  showReportDialog,
  init as initSentry,
} from '@sentry/browser';
import { localStorageService } from '@pluralcom/plural-web-utils';

import logger from '../logger/logger';
import environment from '../environment/environment';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

/**
 * Show sentry report dialog with user data from localstorage
 */
const showReportDialogWithUser = (options?: Object) => {
  const { name, email, username } = localStorageService.getItem('user') || {};
  showReportDialog({
    user: {
      name: name || username,
      email,
    },
    ...options,
  });
};

/**
 * Fired before sentry sends an event
 */
const beforeSend = event => {
  // Check if it is an exception and the user is an admin, if so, show the report dialog
  if (event.exception) {
    showReportDialogWithUser();
  }
  return event;
};

/**
 * Sentry configurations
 * https://sentry.io/settings/plural/web
 */
const sentryConfig = {
  dsn: SENTRY_DSN,
  environment: environment.getHostEnv(),
  release: process.env.REACT_APP_VERSION,
  beforeSend,
};

const setScopeUser = (user?: {
  id: ?string,
  username: ?string,
  email: ?string,
}) => {
  const currUser = user || localStorageService.getItem('user');
  if (currUser) {
    configureScope(scope => {
      const { username, id, email } = currUser;
      scope.setUser({ username, id, email });
      scope.setTag('username', username);
      scope.setTag('user.id', id);
    });
  }
};

const initialConfigureScope = () => {
  configureScope(scope => {
    scope.setTag('hostname', window.location.hostname);
  });
  setScopeUser();
};

/**
 * Sets up sentry in case of a remote deployment
 */
const init = (config: Object = {}) => {
  if (!environment.isRemote() && !process.env.REACT_APP_DEV_FORCE_SENTRY) {
    return;
  }
  if (!SENTRY_DSN) {
    logger.error(
      '❌ 🚨 Did not setup sentry due to missing SENTRY_DSN variable.'
    );
    return;
  }
  initSentry({
    ...sentryConfig,
    ...config,
  });
  initialConfigureScope();
};

export default {
  addBreadcrumb,
  init,
  captureEvent,
  captureException,
  captureMessage,
  configureScope,
  showReportDialog,
  setScopeUser,
};
