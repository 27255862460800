/* @flow */
/**
 * - Using Script as it passes along a lot of information on the users' location, machine, etc. by default.
 * That's not the case with the npm module, which is why it's not used.
 *
 * - Always use window.mixpanel as destructuring doesn't work
 */

import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import environment from '../environment/environment';
import logger from '../logger/logger';
import waitForVar from '../waitForVar/waitForVar';

const config = {
  debug: !environment.isProduction(),
  /** @legal @important - note that this has to be in the ToU/PP - it's there as of jun 2020 */
  ignore_dnt: true,
};

/** Mixpanel object - no destructuring to ensure reference exists */

/** Setup */
if (!environment.isTest()) {
  waitForVar('mixpanel')
    .then(() => {
      window.mixpanel.set_config(config);
      /** Register super properties */
      window.mixpanel.register({
        'App Name': 'admin_web',
        'Web App Version': environment.getAppPackageVersion(),
        'Web App Full Version': environment.getAppVersion(),
        'Web App Build Number': environment.getAppBuildNumber(),
      });
    })
    .catch((err) => {
      logger.error('Error waiting for Mixpanel', err);
    });
}

/**
 * Aliases the actions of the current user.
 * To be called post Signup to alias the anonymous user actions to the newly signed up account
 */
const setAlias = (user: { id: string }) => {
  if (!user?.id) {
    return;
  }
  window.mixpanel.alias(user.id);
};

/**
 * Sets the mixpanel user.
 * To be called upon authentication and upon opening the website by an authenticated user
 */
const setUser = (user, { roles }: { roles: Array<string> } = {}) => {
  if (!user?.id) {
    return;
  }
  window.mixpanel.identify(user.id);
  window.mixpanel.people.set(
    omitBy(
      {
        id: user.id,
        username: user.username,
        birthdate: user.birthdate,
        gender: user.gender,
        created_at: user.created_at,
        published_at: user.published_at,
        website: user.website,
        roles,
        // mixpanel preset keys
        // $distinct_id: user.id, // distinct_id is controlled by mixpanel
        $email: user.email,
        $phone: user.phone,
        $avatar: user?.avatar?.thumbnail || user?.avatar?.smallThumbnail,
        $first_name: user.first_name,
        $last_name: user.last_name,
        $name: user.name,
        $created: user.published_at,
      },
      isEmpty,
    ),
  );
  window.mixpanel.people.set_once({
    'First Login Date': new Date(),
  });
};

/**
 * Tracks an analytics event
 */
const trackEvent = (name: string, properties?: ?Object) =>
  window.mixpanel.track(`${name}`, properties);

/**
 * A curried version of trackEvent.
 * To be used on elements ie: onClick
 */
const trackEventAndPass = (
  analyticsEvent: ?{
    name: string,
    properties?: ?Object,
  },
  {
    /** If true -> tracks the analyticsEvent even if func is falsy */
    forceTrack,
  } = {},
) => (func?: ?Function) => (e?: ?Event) => {
  if (func || forceTrack) {
    if (analyticsEvent) {
      trackEvent(analyticsEvent.name, analyticsEvent.properties);
    }
    return func && func(e);
  }
  return func;
};

/** Fully Resets the mixpanel user's data */
const reset = () => window.mixpanel.reset();

/** Opts the user in to analytics */
const optIn = () => {
  window.mixpanel.opt_in_tracking();
};

export default {
  setUser,
  setAlias,
  trackEvent,
  trackEventAndPass,
  reset,
  optIn,
};
