/* @flow */
import React from 'react';
import classNames from 'classnames';

import Navbar from '../../containers/Navbar/Navbar';
import Spinner from '../Spinner/Spinner';

import styles from './LoadingLayout.module.scss';

type Props = {
  className?: ?string,
  absolutePositionCenter?: ?boolean,
};

const LoadingLayout = ({
  className,
  absolutePositionCenter,
  ...rest
}: Props) => (
  <div>
    <Navbar />
    <section className={styles.content}>
      <Spinner
        className={classNames([
          styles.container,
          { [styles['position-center']]: absolutePositionCenter },
          className,
        ])}
        {...rest}
      />
    </section>
  </div>
);

export default LoadingLayout;
