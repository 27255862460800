/**
 * @generated SignedSource<<f0cf92ec8123d8002696f18ef9835b46>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type Navbar_viewer$fragmentType: FragmentType;
export type Navbar_viewer$data = {|
  +navbarProfile: ?{|
    +id: string,
    +username: ?string,
  |},
  +$fragmentType: Navbar_viewer$fragmentType,
|};
export type Navbar_viewer$key = {
  +$data?: Navbar_viewer$data,
  +$fragmentSpreads: Navbar_viewer$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navbar_viewer",
  "selections": [
    {
      "alias": "navbarProfile",
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};

(node/*: any*/).hash = "33107d6142506b32549ea5fc188c348a";

module.exports = ((node/*: any*/)/*: Fragment<
  Navbar_viewer$fragmentType,
  Navbar_viewer$data,
>*/);
