/* @flow */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const RemoveTagFromFAQuestionMutation = ({
  question_id,
  tag_id,
}: {
  question_id: string,
  tag_id: string,
  question: string,
  tag_title: string,
}): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation RemoveTagFromFAQuestionMutation(
        $input: RemoveTagFromFAQuestionInput!
      ) {
        removeTagFromFAQuestion(input: $input) {
          error {
            id
            title
            message
            technical_message
            type
          }
          question {
            id
          }
          deleted_tag_faQuestion_id
        }
      }
    `;

    const variables = {
      input: {
        question_id,
        tag_id,
      },
    };

    const optimisticResponse = {
      removeTagFromFAQuestion: {
        error: null,
        question: {
          id: `_${Math.random().toString(36).substr(2, 9)}`,
        },
        deleted_tag_faQuestion_id: `_${Math.random()
          .toString(36)
          .substr(2, 9)}`,
      },
    };
    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID: question_id,
        connectionKeys: [
          {
            key: 'SynonymsTags_synonymsTags',
          },
        ],
        deletedIDFieldName: 'deleted_tag_faQuestion_id',
        pathToConnection: ['allFAQuestions', 'connected_tags'],
      },
    ];

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default RemoveTagFromFAQuestionMutation;
