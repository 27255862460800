/**
 * @generated SignedSource<<2d96ac0fb6d5a5dbd935bc8cebee9e9c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCategoryTagInput = {|
  title: string,
  primaryTagId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateCategoryTagMutation$variables = {|
  input: CreateCategoryTagInput,
|};
export type CreateCategoryTagMutation$data = {|
  +createCategoryTag: ?{|
    +categoryTag: ?{|
      +id: string,
      +title: ?string,
      +tags_with_this_category: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
    |},
    +tagsEdges: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
          +slug: ?string,
          +remote: ?boolean,
          +do_not_suggest: ?boolean,
          +boost: ?boolean,
          +block: ?boolean,
          +used_at: ?any,
          +corrected_to_tag: ?{|
            +id: string,
            +title: ?string,
          |},
          +categories: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +synonyms: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +skillTags: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +tags_with_this_category: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
        |},
      |}>,
    |},
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type CreateCategoryTagMutation = {|
  variables: CreateCategoryTagMutation$variables,
  response: CreateCategoryTagMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tags_with_this_category",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "categoryTag",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "TagConnection",
  "kind": "LinkedField",
  "name": "tagsEdges",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "do_not_suggest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "block",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "used_at",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "corrected_to_tag",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "categories",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "synonyms",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "skillTags",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCategoryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCategoryTagPayload",
        "kind": "LinkedField",
        "name": "createCategoryTag",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCategoryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCategoryTagPayload",
        "kind": "LinkedField",
        "name": "createCategoryTag",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b520afed90cf2bb4c77ccf7c2e5d7535",
    "id": null,
    "metadata": {},
    "name": "CreateCategoryTagMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCategoryTagMutation(\n  $input: CreateCategoryTagInput!\n) {\n  createCategoryTag(input: $input) {\n    categoryTag {\n      id\n      title\n      tags_with_this_category {\n        id\n        title\n      }\n    }\n    tagsEdges {\n      edges {\n        node {\n          id\n          title\n          slug\n          remote\n          do_not_suggest\n          boost\n          block\n          used_at\n          corrected_to_tag {\n            id\n            title\n          }\n          categories {\n            id\n            title\n          }\n          synonyms {\n            id\n            title\n          }\n          skillTags {\n            id\n            title\n          }\n          tags_with_this_category {\n            id\n            title\n          }\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "525afb7b37b7b80f4461d440147e5025";

module.exports = ((node/*: any*/)/*: Mutation<
  CreateCategoryTagMutation$variables,
  CreateCategoryTagMutation$data,
>*/);
