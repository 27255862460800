/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation CreateSynonymTagMutation($input: CreateSynonymTagInput!) {
    createSynonymTag(input: $input) {
      synonymTag {
        id
        title
      }
      tagsEdges {
        edges {
          node {
            id
            title
            slug
            remote
            do_not_suggest
            boost
            block
            used_at
            corrected_to_tag {
              id
              title
            }
            categories {
              id
              title
            }
            synonyms {
              id
              title
            }
            skillTags {
              id
              title
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;

const sharedUpdater = (store, newSynonymTag, primaryTagId, newEdges) => {
  const primaryTag = store.get(primaryTagId);
  let primaryTagSynonyms = primaryTag.getLinkedRecords('synonyms');
  primaryTagSynonyms = primaryTagSynonyms.concat(newSynonymTag);
  primaryTag.setLinkedRecords(primaryTagSynonyms, 'synonyms');

  if (!newEdges?.length) {
    return;
  }

  const rootField = store.getRoot();
  const viewerField = rootField.getLinkedRecord('viewer');

  const _addToConnection = ([connectionName, filters]) => {
    const connection = ConnectionHandler.getConnection(
      viewerField,
      connectionName,
      filters,
    );
    if (connection) {
      newEdges.forEach((newEdge) => {
        ConnectionHandler.insertEdgeBefore(connection, newEdge);
      });
      connection.setValue(
        connection.getValue('totalCount') + newEdges.length,
        'totalCount',
      );
    }
  };

  // backend auto boosts synonyms
  [
    ['TagsViewAll_tags'],
    ['TagsViewUsed_tags'],
    ['TagsViewCurate_tags'],
    ['PrimaryTagAutoComplete_suggestionTags', { term: '' }],
    [
      'PrimaryTagAutoComplete_suggestionTags',
      { term: '', filter: { boost: true } },
    ],
  ]
    .filter(Boolean)
    .forEach((connectionName) => _addToConnection(connectionName));
};

export default ({
  title,
  primaryTagId,
}: {
  title: string,
  primaryTagId: string,
}) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        title,
        primaryTagId,
      },
    };

    commitMutation({
      mutation,
      variables,

      optimisticUpdater: (store) => {
        const id = `OPUP:Tag:${`${Math.random()}`.substring(2, 10)}`;
        const newSynonymTag = store.create(id, 'Tag');
        newSynonymTag.setValue(id, 'id');
        newSynonymTag.setValue(title, 'title');
        newSynonymTag.setValue(true, 'boost'); // backend auto boosts synonyms
        newSynonymTag.setValue(new Date().toISOString(), 'used_at');
        newSynonymTag.setValue(new Date().toISOString(), 'created_at');

        sharedUpdater(store, newSynonymTag, primaryTagId);
      },
      updater: (store) => {
        const payload = store.getRootField('createSynonymTag');
        const newSynonymTag = payload.getLinkedRecord('synonymTag');
        const error = payload.getLinkedRecord('error');

        const tagsEdges = payload
          .getLinkedRecord('tagsEdges')
          ?.getLinkedRecords('edges');

        if (!error) {
          sharedUpdater(store, newSynonymTag, primaryTagId, tagsEdges);
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
