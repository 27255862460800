/** @flow */
/* eslint no-console: 0 */

type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error';

const _defaultEnabledLevels: Array<LogLevel> = [
  'trace',
  'debug',
  'info',
  'warn',
  'error',
];

/**
 * Simple console logger
 */
class Logger {
  constructor(
    {
      isEnabled = true,
      loggerLevel,
    }: {
      isEnabled: boolean,
      loggerLevel?: ?LogLevel,
    } = {
      isEnabled: true,
    }
  ) {
    this._isEnabled = isEnabled;
    if (loggerLevel) {
      this.setEnabledLevelSubset(loggerLevel);
    }
  }

  /**
   * Whether the logger is enabled
   */
  _isEnabled = true;

  /**
   * Current enabledLevels to be logged
   */
  _enabledLevels: Array<LogLevel> = _defaultEnabledLevels.filter(x => x);

  /**
   * Whether the Extra info middleware enabled
   */
  _isExtraInfoMiddlewareEnabled = true;

  /**
   * Enables or disables the logger
   */
  setEnabled = (isEnabled: boolean) => {
    this._isEnabled = isEnabled;
  };

  /**
   * Sets the enabled levels
   */
  setEnabledLevels = (levels: Array<LogLevel>) => {
    this._enabledLevels = levels;
  };

  /**
   * Sets the enabled levels to the input level and higher
   */
  setEnabledLevelSubset = (level: LogLevel) => {
    this._enabledLevels = _defaultEnabledLevels.slice(
      _defaultEnabledLevels.indexOf(level)
    );
  };

  /**
   * middleware function for all logs
   */
  _middleware = (
    logFunc: Function,
    logFuncParams: any,
    { level }: { level: LogLevel }
  ) => {
    if (!this._isEnabled || !this._enabledLevels.includes(level)) {
      return;
    }
    if (this._isExtraInfoMiddlewareEnabled) {
      logFunc(...logFuncParams, 'Extra info:', {
        timestamp: new Date().toISOString(),
        level,
      });
    } else {
      logFunc(...logFuncParams);
    }
  };

  /**
   * Prints to stdout with newline.
   */
  debug = (...params: any) => {
    this._middleware(console.log, params, { level: 'debug' });
  };

  /**
   * Prints to stderr with newline.
   */
  debugError = (...params: any) => {
    this._middleware(console.error, params, { level: 'debug' });
  };

  /**
   * Prints to stderr with newline.
   */
  error = (...params: any) => {
    this._middleware(console.error, params, { level: 'error' });
  };

  /**
   * The {@link console.info()} function is an alias for {@link console.log()}.
   */
  info = (...params: any) => {
    this._middleware(console.info, params, { level: 'info' });
  };

  /**
   * Prints to stderr the string 'Trace :', followed by the {@link util.format()} formatted message and stack trace to the current position in the code.
   */
  trace = (...params: any) => {
    this._middleware(console.trace, params, { level: 'trace' });
  };

  /**
   * The {@link console.warn()} function is an alias for {@link console.error()}.
   */
  warn = (...params: any) => {
    this._middleware(console.warn, params, { level: 'warn' });
  };
}

export default Logger;
