/**
 * @generated SignedSource<<380346791b347004bf1ab126c9964c04>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddBetaSignupsInput = {|
  entries: $ReadOnlyArray<?BetaSignupEntryInput>,
  clientMutationId?: ?string,
|};
export type BetaSignupEntryInput = {|
  email?: ?string,
  phone?: ?string,
  first_name?: ?string,
  last_name?: ?string,
  allowed_auth?: ?boolean,
|};
export type addBetaSignupsMutation$variables = {|
  input: AddBetaSignupsInput,
|};
export type addBetaSignupsMutation$data = {|
  +addBetaSignups: ?{|
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type addBetaSignupsMutation = {|
  variables: addBetaSignupsMutation$variables,
  response: addBetaSignupsMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addBetaSignupsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBetaSignupsPayload",
        "kind": "LinkedField",
        "name": "addBetaSignups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addBetaSignupsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBetaSignupsPayload",
        "kind": "LinkedField",
        "name": "addBetaSignups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad4ffc6dcdfd64df248140558dbfa9fe",
    "id": null,
    "metadata": {},
    "name": "addBetaSignupsMutation",
    "operationKind": "mutation",
    "text": "mutation addBetaSignupsMutation(\n  $input: AddBetaSignupsInput!\n) {\n  addBetaSignups(input: $input) {\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "dfe75a603be85890d6ccfc072ecdf45c";

module.exports = ((node/*: any*/)/*: Mutation<
  addBetaSignupsMutation$variables,
  addBetaSignupsMutation$data,
>*/);
