/**
 * @generated SignedSource<<b6e161d70cf4e7b252a6c7b47b6ae2f6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditFAQuestionInput = {|
  question_id: string,
  question?: ?string,
  general?: ?boolean,
  clientMutationId?: ?string,
|};
export type EditFAQuestionMutation$variables = {|
  input: EditFAQuestionInput,
|};
export type EditFAQuestionMutation$data = {|
  +editFAQuestion: ?{|
    +error: ?{|
      +id: string,
      +title: ?string,
      +message: ?string,
    |},
    +question: ?{|
      +id: string,
      +question: string,
      +general: ?boolean,
    |},
  |},
|};
export type EditFAQuestionMutation = {|
  variables: EditFAQuestionMutation$variables,
  response: EditFAQuestionMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditFAQuestionPayload",
    "kind": "LinkedField",
    "name": "editFAQuestion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FAQuestion",
        "kind": "LinkedField",
        "name": "question",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "question",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "general",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditFAQuestionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditFAQuestionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "67de76ca8382849ea64319de314ffd02",
    "id": null,
    "metadata": {},
    "name": "EditFAQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation EditFAQuestionMutation(\n  $input: EditFAQuestionInput!\n) {\n  editFAQuestion(input: $input) {\n    error {\n      id\n      title\n      message\n    }\n    question {\n      id\n      question\n      general\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "420f2ba72427e68f4d9fcb65a5cab216";

module.exports = ((node/*: any*/)/*: Mutation<
  EditFAQuestionMutation$variables,
  EditFAQuestionMutation$data,
>*/);
