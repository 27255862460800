import { commitMutation as commitMutationRaw } from 'react-relay';
import configMiddleware from './configMiddleware';

const commitMutation = (environment, config) =>
  commitMutationRaw(environment, configMiddleware(config));

export { commitMutationRaw, commitMutation };

export default (config) =>
  commitMutation(
    // eslint-disable-next-line global-require
    require('../Environment').getEnvironment(),
    config,
  );
