/* @flow */
import { useState } from 'react';

/**
 * useRelayLoadMore hook
 *
 * A hook for using relay's loadMore functionality with state
 */
const useRelayLoadMore = ({
  /** Current relay instance */
  relay,
  /** Initial state of `isLoadingMore` variable */
  initialState = false,
}: {
  relay: Object,
  initialState?: boolean,
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(initialState);
  const [isRefetching, setIsRefeching] = useState(false);
  /**
   * loads more data from relay
   *
   * 1. Sets isLoadingMore to true
   * 1. Loads more data from relay
   * 1. Sets isLoadingMore to false
   *
   * Returns the RelayObservable of the loadMore call
   *
   * - Note: If you use RelayObservable to cancel the call, remember to setIsLoadingMore(false) if needed.
   */
  const loadMore = (count: number, cb: ?Function, ...rest): RelayObservable => {
    setIsLoadingMore(true);
    return relay.loadMore(
      count,
      (...cbArgs) => {
        setIsLoadingMore(false);
        if (typeof cb === 'function') {
          cb(...cbArgs);
        }
      },
      ...rest,
    );
  };

  /**
   * Async version of `loadMore`
   *
   * Returns a promise that resolves or rejects with the inputs of `cb`
   */
  const loadMoreAsync = (count: number, cb: ?Function, ...rest): Promise<*> =>
    new Promise((resolve, reject) => {
      loadMore(
        count,
        (error, ...cbArgs) => {
          if (typeof cb === 'function') {
            cb(error, ...cbArgs);
          }
          if (error) {
            reject(error, ...cbArgs);
          } else {
            resolve(error, ...cbArgs);
          }
        },
        ...rest,
      );
    });

  const refetchConnection = (
    count: number,
    cb: ?Function,
    ...rest
  ): RelayObservable => {
    setIsRefeching(true);
    return relay.refetchConnection(
      count,
      (...cbArgs) => {
        setIsRefeching(false);
        if (typeof cb === 'function') {
          cb(...cbArgs);
        }
      },
      ...rest,
    );
  };

  return {
    /** Whether Relay is currently fetching data */
    isLoadingMore,
    isRefetching,
    /** Force set the value of `isLoadingMore` */
    setIsLoadingMore,
    loadMore,
    loadMoreAsync,
    refetchConnection,
  };
};

export default useRelayLoadMore;
