/**
 * @generated SignedSource<<77843f6572096be9ae0143f496c50bef>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePrimaryTagInput = {|
  id: string,
  title?: ?string,
  emoji?: ?string,
  remote?: ?boolean,
  boost?: ?boolean,
  do_not_suggest?: ?boolean,
  block?: ?boolean,
  is_smart?: ?boolean,
  clientMutationId?: ?string,
|};
export type UpdatePrimaryTagMutation$variables = {|
  input: UpdatePrimaryTagInput,
|};
export type UpdatePrimaryTagMutation$data = {|
  +updatePrimaryTag: ?{|
    +primaryTag: ?{|
      +id: string,
      +title: ?string,
      +slug: ?string,
      +emoji: ?string,
      +is_smart: ?boolean,
      +remote: ?boolean,
      +boost: ?boolean,
      +do_not_suggest: ?boolean,
      +block: ?boolean,
      +used_at: ?any,
    |},
    +tagsEdges: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
          +slug: ?string,
          +emoji: ?string,
          +remote: ?boolean,
          +boost: ?boolean,
          +do_not_suggest: ?boolean,
          +block: ?boolean,
          +used_at: ?any,
        |},
      |}>,
    |},
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type UpdatePrimaryTagMutation = {|
  variables: UpdatePrimaryTagMutation$variables,
  response: UpdatePrimaryTagMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remote",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boost",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "do_not_suggest",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "block",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "used_at",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "primaryTag",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_smart",
      "storageKey": null
    },
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "TagConnection",
  "kind": "LinkedField",
  "name": "tagsEdges",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "updatePrimaryTag",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "updatePrimaryTag",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebbadeeba01074121032d8f77e738c02",
    "id": null,
    "metadata": {},
    "name": "UpdatePrimaryTagMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePrimaryTagMutation(\n  $input: UpdatePrimaryTagInput!\n) {\n  updatePrimaryTag(input: $input) {\n    primaryTag {\n      id\n      title\n      slug\n      emoji\n      is_smart\n      remote\n      boost\n      do_not_suggest\n      block\n      used_at\n    }\n    tagsEdges {\n      edges {\n        node {\n          id\n          title\n          slug\n          emoji\n          remote\n          boost\n          do_not_suggest\n          block\n          used_at\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1195c150d5e1a9f78f7cdc327f89a0d8";

module.exports = ((node/*: any*/)/*: Mutation<
  UpdatePrimaryTagMutation$variables,
  UpdatePrimaryTagMutation$data,
>*/);
