/* @flow weak */

import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation addBetaSignupsMutation($input: AddBetaSignupsInput!) {
    addBetaSignups(input: $input) {
      error {
        message
      }
    }
  }
`;

export default (entries) =>
  new Promise((resolve, reject) => {
    const variables = { input: { entries } };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });
