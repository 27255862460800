/* @flow */
import cloneDeep from 'lodash/cloneDeep';
import { RecordSourceInspector } from 'relay-runtime';
import { getCache } from './Environment';

export const setNodeValues = (node: Object, data: Object) => {
  Object.keys(data).forEach((key) => {
    node.setValue(data[key], key);
  });
};

export const setNodeLinkedRecords = (node: Object, data: Object) => {
  Object.keys(data).forEach((key) => {
    node.setLinkedRecords(data[key], key);
  });
};

export const stripId = (item: Object): Object => {
  const newItem = cloneDeep(item);
  delete newItem.id;
  return newItem;
};

export const getSourceRecords = () => {
  try {
    return new RecordSourceInspector(getCache())._source._records;
  } catch (e) {
    return {};
  }
};
