/* @flow */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const editFAQuestion = ({
  id,
  question,
  general,
}: {
  question: string,
  general: boolean,
}): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation EditFAQuestionMutation($input: EditFAQuestionInput!) {
        editFAQuestion(input: $input) {
          error {
            id
            title
            message
          }
          question {
            id
            question
            general
          }
        }
      }
    `;

    const variables = {
      input: {
        question_id: id,
        question,
        general,
      },
    };

    const optimisticResponse = {
      editFAQuestion: {
        error: null,
        question: {
          id,
          question,
          general,
        },
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default editFAQuestion;
