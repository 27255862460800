/**
 * @generated SignedSource<<2e05aace28df9459d148c52692dbf089>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeletePrimaryTagInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeletePrimaryTagMutation$variables = {|
  input: DeletePrimaryTagInput,
|};
export type DeletePrimaryTagMutation$data = {|
  +deletePrimaryTag: ?{|
    +primaryTag: ?{|
      +id: string,
    |},
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type DeletePrimaryTagMutation = {|
  variables: DeletePrimaryTagMutation$variables,
  response: DeletePrimaryTagMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "primaryTag",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeletePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "deletePrimaryTag",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeletePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "deletePrimaryTag",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc0df516ebcb11f79196523db4e02f45",
    "id": null,
    "metadata": {},
    "name": "DeletePrimaryTagMutation",
    "operationKind": "mutation",
    "text": "mutation DeletePrimaryTagMutation(\n  $input: DeletePrimaryTagInput!\n) {\n  deletePrimaryTag(input: $input) {\n    primaryTag {\n      id\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3ae7da87266b5e586c7245ab36ce480c";

module.exports = ((node/*: any*/)/*: Mutation<
  DeletePrimaryTagMutation$variables,
  DeletePrimaryTagMutation$data,
>*/);
