/* @flow weak */

import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation editSkillParametersMutation($input: EditSkillParametersInput!) {
    editSkillParameters(input : $input){
      skill {
        id,
        boost,
        do_not_suggest,
        blocked,
      }
      error {
        technical_message
        message
      }
    } 
  }
`;

export default ({
  skill_id,
  boost,
  do_not_suggest,
  blocked,
}) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        skill_id,
        boost,
        do_not_suggest,
        blocked,
      }
    };

    const optimisticResponse = {
      error: null, 
      user: {
        id: skill_id,
        boost, 
        do_not_suggest,
        blocked,    
      }
    }

    commitMutation({
      mutation,
      variables,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });
