/* @flow */
import React from 'react';

import styles from './AddItem.module.scss';

type Props = {
  children: ReactNode,
  isInput: boolean,
  setIsInput: (boolean) => any,
};

const AddItem = ({ children, isInput, setIsInput }: Props) => {
  if (!isInput) {
    return (
      <button
        type="button"
        className={styles.button}
        onClick={() => setIsInput(true)}
      >
        + Add
      </button>
    );
  }

  return children;
};

export default AddItem;
